import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../../helpers/Footer/Footer';
import Header from '../../helpers/Header/Header';
import citiesService from '../../services/cities.service';
import patientsService from '../../services/patients.service';
import ReCAPTCHA from "react-google-recaptcha";
import './ContactPage.css'

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                ...this.initialFields,
            },
            errors: {},
            isVevified: false,
            authonticatedUser: null,
            emailSnt: false,
            conditionsCheck: false,
            conditionsCheckError: false,
            recaptchaError: false,
            img: null
        };
        this.handleChange = this.handleChange.bind(this)
    }


    initialFields = {
        fullName: '',
        email: '',
        mobileNumber: '',
        city: '',
        message: '',
    }

    componentDidMount() {
        document.title = "MonPharmacien - 1er annuaire interactif de pharmacies au Maroc"
        this.getCurrentPatient()
        this.creatImage()
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ authonticatedUser: response.data })
        } catch (error) {
            console.log(error);
        }
    }

    handleChange = (input) => (e) => {
        let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
        fieldsCopy[input] = e.target.value;
        this.setState({ fields: fieldsCopy });
    };

    handleValidation = async () => {
        const { fields, conditionsCheck, isVevified } = this.state;
        let errors = {};
        let conditionsCheckError = null
        let recaptchaError = null
        let formIsValid = true;

        if (fields["fullName"] === "") {
            formIsValid = false;
            errors["fullName"] = "Ce champ est requis";
        }

        if (fields["message"] === "") {
            formIsValid = false;
            errors["message"] = "Ce champ est requis";
        }

        if (fields["mobileNumber"] === "") {
            formIsValid = false;
            errors["mobileNumber"] = "Ce champ est requis";
        }

        if (fields["mobileNumber"].length < 10 && fields["mobileNumber"].length > 0) {
            formIsValid = false;
            errors["mobileNumber"] = "Minimum 10 caractères";
        }

        if (fields["mobileNumber"] && fields["mobileNumber"] !== "") {
            if (
                !fields["mobileNumber"].match(
                    /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))/
                )
            ) {
                formIsValid = false;
                errors["mobileNumber"] = "Numéro invalide";
            }
        }

        if (fields["email"] === "") {
            formIsValid = false;
            errors["email"] = "Ce champ est requis";
        }

        if (fields["email"] !== "") {
            let lastAtPos = fields["email"].lastIndexOf("@");
            let lastDotPos = fields["email"].lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf("@@") === -1 && lastDotPos > 2 && fields["email"].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["email"] = "e-mail n'est pas valide";
            }
        }

        // if (conditionsCheck === false) {
        //     formIsValid = false;
        //     conditionsCheckError = true
        // }

        if (isVevified === false) {
            formIsValid = false;
            recaptchaError = true
        }

        this.setState({ errors: { ...this.state.errors, ...errors, }, conditionsCheckError, recaptchaError });
        return formIsValid;
    };

    emailNotefitacion() {
        setTimeout(() => {
            console.log('ive been called!');
            this.setState({ emailSnt: false })
        }, 2000);
    }

    contactUs = async () => {
        this.setState({ errors: {}, conditionsCheckError: false, recaptchaError: false }, async () => {
            let valid = await this.handleValidation();
            if (valid) {
                try {
                    await citiesService.contactUs(this.state.fields);
                    // const fieldsCopy = this.state.fields
                    const fieldsCopy = {
                        ... this.state.fields,
                        fullName: '',
                        email: '',
                        mobileNumber: '',
                        city: '',
                        message: '',
                    }
                    this.setState({ fields: fieldsCopy, emailSnt: true, conditionsCheck: false, isVevified: false }, () => window.scrollTo(0, 0),
                        this.emailNotefitacion())
                } catch (error) {
                    console.error(error);
                }
            }
        });

    }

    onChange(value) {
        console.log(value);
        if (value !== null) this.setState({ isVevified: true });
        console.log("isVevified", this.state.isVevified);
    }

    creatImage = () => {
        let canvasTxt = document.getElementById("canvasComponent").getContext("2d");
        canvasTxt.canvas.width = canvasTxt.measureText('support@sobrus.com').width + 30;
        canvasTxt.canvas.height = 22;
        canvasTxt.canvas.fillStyle = "#00c389";
        canvasTxt.fillStyle = "#00c389";
        canvasTxt.canvas.font = "12px openSans";
        canvasTxt.font = "12px openSans";
        canvasTxt.fillText("support@sobrus.com", 0, 14);
        this.setState({ img: canvasTxt.canvas.toDataURL() });
    }

    render() {
        const { recaptchaError, fields, errors, emailSnt } = this.state
        return (
            <div className="ContactPageComponent">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine" />
                </Helmet>
                {
                    emailSnt && <div className="sign_up_notification">
                        <i className="far fa-check-circle"></i>
                        <p>Votre message a été bien envoyé</p>
                    </div>
                }
                <Header />
                <div className="ContactForm">
                    <div className="ContactFormHeader">
                        <h2 className="ContactFormTitle">CONTACTEZ-NOUS</h2>
                        <p className="ContactFormparagraphe">On est toujours à votre écoute n'hésitez pas à nous contacter en remplissant
    le formulaire ci-dessous.</p>
                    </div>
                    <div className="container ContactFormCorp">
                        <div className="col-10 offset-1">
                            <div className="row">
                                <div className="col-8 offset-2">
                                    {/* <form
                                     onSubmit={this.handleSubmit.bind(this)} method="POST"
                                     > */}
                                    <div className="form-group d-flex justify-content-between">
                                        <div className="col-6 ContactInputAndLabel">
                                            <label className=" ContactFormLabels">Nom et Prénom* :</label>
                                            <input type="text" className="form-control ContactFormInputs" id="exampleInputNom" aria-describedby="emailHelp" placeholder="Entrez votre nom complet" error={errors["fullName"]} onChange={this.handleChange("fullName")} value={fields["fullName"]} />
                                            {errors["fullName"] ? <p className="erreurInputText"><><i className="far fa-times-circle"></i> {errors["fullName"]}</> </p> : ""}
                                        </div>
                                        <div className="col-6 ContactInputAndLabel">
                                            <label className=" ContactFormLabels">Email* :</label>
                                            <input type="email" className="form-control ContactFormInputs" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Entrez votre email" error={errors["email"]} onChange={this.handleChange("email")} value={fields["email"]} />
                                            {errors["email"] ? <p className="erreurInputText"><><i className="far fa-times-circle"></i> {errors["email"]}</> </p> : ""}
                                        </div>
                                    </div>
                                    <div className="form-group d-flex justify-content-between">
                                        <div className="col-6 ContactInputAndLabel">
                                            <label className=" ContactFormLabels">Numéro de téléphone* :</label>
                                            <input type="text" className="form-control ContactFormInputs" id="exampleInputTelephone" placeholder="Entrez votre téléphone" error={errors["mobileNumber"]} onChange={this.handleChange("mobileNumber")} value={fields["mobileNumber"]} />
                                            {errors["mobileNumber"] ? <p className="erreurInputText"><><i className="far fa-times-circle"></i> {errors["mobileNumber"]}</> </p> : ""}
                                        </div>
                                        <div className="col-6 ContactInputAndLabel">
                                            <label className=" ContactFormLabels">Ville :</label>
                                            <input type="text" className="form-control ContactFormInputs" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Entrez votre ville" error={errors["city"]} onChange={this.handleChange("city")} value={fields["city"]} />
                                            {errors["city"] ? <p className="erreurInputText"><><i className="far fa-times-circle"></i> {errors["city"]}</> </p> : ""}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className=" ContactFormLabels">Message* :</label>
                                        <textarea className="form-control ContactInput ContactFormTextErea" placeholder="Entrez votre message" rows="5" error={errors["message"]} onChange={this.handleChange("message")} value={fields["message"]} />
                                        {errors["message"] ? <p className="erreurInputText"><><i className="far fa-times-circle"></i> {errors["message"]}</> </p> : ""}
                                    </div>
                                    <div className="form-group">
                                        <p className="form_group_paraphe">* Champs obligatoires </p>
                                        <p className="form_group_paraphe">Par le biais de ce formulaire, MonPharmacien collecte vos données personnelles en vue de répondre à votre demande suite à l’envoi du formulaire de contact.
Ce traitement a fait l’objet d’une déclaration de la CNDP sous le numéro <strong>D-GC-252/2018.</strong></p>
                                        <p className="form_group_paraphe">Vous pouvez vous adresser à Sobrus par e-mail à l’adresse suivante : <a><canvas id="canvasComponent" style={{ display: "none" }} /><img src={this.state.img} /></a> pour exercer vos droits d’accès, de rectification et d’opposition conformément aux dispositions de la loi 09-08.</p>
                                        {/* <p className={this.state.conditionsCheckError ? "form_group_paraphe_error" : "form_group_paraphe"}>* Champs obligatoires </p>
                                        <div className="form-check custom-checkbox">
                                            <input type="checkbox" className="form-check-input custom-control-input checkbox" id="ContactPageComponent" onChange={() => this.setState({ conditionsCheck: !this.state.conditionsCheck })} checked={this.state.conditionsCheck} />
                                            <label className="form-check-label custom-control-label" htmlFor="ContactPageComponent" >
                                                <p className="form_group_paraphe">Par le biais de ce formulaire, Sobrus collecte vos données personnelles en vue de répondre à votre demande suite à l’envoi du formulaire de contact.
Ce traitement a fait l’objet d’une déclaration de la CNDP sous le numéro <strong>D-GC-252/2018.</strong></p>
                                                <p className="form_group_paraphe">Vous pouvez vous adresser à Sobrus par e-mail à l’adresse suivante : <a>support@sobrus.com</a> pour exercer vos droits d’accès, de rectification et d’opposition conformément aux dispositions de la loi 09-08.</p>
                                            </label>
                                        </div> */}
                                    </div>
                                    <div className="ContactButton d-flex justify-content-between align-items-end">
                                        <div className="reCAPATCHA">
                                            <ReCAPTCHA
                                                sitekey="6LcuamcbAAAAAClYlEEJAY46DPZs3UOFffv3ftO0"
                                                onChange={() => this.setState({ isVevified: true }, () => console.log(this.state.isVevified))}
                                                hl="fr"
                                            />
                                            {recaptchaError && <p className="form_group_paraphe_error">* Champs obligatoires </p>}
                                        </div>
                                        <button className="btn ContactFormButton" onClick={() => this.contactUs()}>Envoyer</button>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default ContactPage;
