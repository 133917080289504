import React, { Component } from 'react'
import LogoMonpharmacien from "../../Images/Logo MonPharmacien.png"
import Facebook from "../../Images/facebook.png"
import SobrusLogoFooter from "../../Images/sobrus_logo_footer.png"
import sprintePhone from "../../Images/sprintePhone.png"
import sprinteEmail from "../../Images/sprinteEmail.png"
import spriteSite from "../../Images/spriteSite.png"
import spriteLocalisation from "../../Images/spriteLocalisation.png"
import Email from "../../Images/email.png"
import pharmaciesServices from '../../services/pharmacies.services';
import moment from 'moment'
import instagram from "../../Images/instagram.png"
import CONTACT_US from "../../Images/contact_us.png"
import { withRouter } from 'react-router-dom'
import './Footer.css';


class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lastUpdate: null
        }
    }

    componentDidMount() {
        this.getPharmacieLastSynchronization()
    }

    getPharmacieLastSynchronization = async () => {
        try {
            let response = await pharmaciesServices.getPharmacieLastSynchronization();
            this.setState({ lastUpdate: response.data })
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        return (
            <div className="Footer">
                <div className="container pt-4">
                    <div className="col-10 offset-1">
                        <div className="row">
                            <div className="col">
                                <img src={LogoMonpharmacien} className="FooterdescriptionImg" />
                                <p className="FooterdescriptionPargaraphe">
                                    MonPharmacien.ma est le premier annuaire interactif de pharmacies au Maroc 100% gratuit.
                                    Cet outil permet de rapprocher le pharmacien de ses patients et de rendre accessibles toutes
                                    les informations des pharmacies pour garantir une meilleure santé. </p>
                                <p className="original_year" >MonPharmacien © 2020</p>
                            </div>

                            <div className="footerSolutions">
                                <ul>
                                    <h4>Nos solutions</h4>
                                    <li><a href="http://pharma.sobrus.com/" target="_blank">Sobrus Pharma</a></li>
                                    <li><a href="http:/labsv3.sobrus.com" target="_blank">Sobrus Labs</a></li>
                                    <li><a href="https://sns.sobrus.com/" target="_blank">Sobrus SNS</a></li>
                                    <li><a href="https://med.sobrus.com/" target="_blank">Sobrus MED</a></li>
                                </ul>
                            </div>

                            <div className="footerSolutions">
                                <ul>
                                    <h4>Nos outils</h4>
                                    <li><a href="http://monmedicament.ma/" target="_blank">Annuaire Médicaments</a></li>
                                    <li  ><a href="https://monordonnance.ma/" target="_blank">Ordonnance Électronique</a></li>
                                    <li><a href="http://chezledocteur.ma/" target="_blank">Annuaire Médecins</a></li>
                                </ul>
                            </div>

                            <div className="footerContact">
                                <ul>
                                    <h4>  Contactez-nous </h4>
                                    <li><img src={sprintePhone} className="sprintePhone" />
                                        <p>0530 500 500</p></li>
                                    <li><img src={sprinteEmail} className="sprinteEmail" />
                                        <p><img src={Email} alt="email" className="ImageEmail" /></p></li>
                                    <li><img src={spriteSite} className="spriteSite" />
                                        <p>sobrus.com</p></li>
                                    <li><img src={CONTACT_US} className="spriteLocalisation" />
                                        <p><a style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => {
                                            this.props.history.push('/Contact');
                                            window.scrollTo(0, 0)
                                        }}>Contactez-nous</a></p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Brand">
                    <div className="container">
                        <div className="col-10 offset-1 py-1">
                            <div className="row d-flex justify-content-between align-items-baseline ">
                                <div className="col-8 d-flex justify-content-between align-items-baseline">
                                    <div>
                                        <a href="https://bit.ly/FbMonPharmacien" target="_blank" className="facebook mr-2"><img src={Facebook} alt="email" /></a>
                                        <a href="https://bit.ly/InstaMonPharmacien" target="_blank" className="instagram"><img src={instagram} alt="email" /></a>
                                    </div>
                                    <p className="BrandParagrphe">Dernière mise à jour le {moment(this.state.lastUpdate).format('DD/MM/YYYY')}</p>
                                    {/* <a className="MON_PHARMACIEN"><img src={MON_PHARMACIEN} alt="email" /></a> */}
                                    {/* <a onClick={() => {
                                        this.props.history.push('/');
                                        window.scrollTo(0, 0)
                                    }}>
                                        <p className="BrandParagrphe">Suggérer une pharmacie</p>
                                    </a> */}
                                    <a>
                                        <p className="BrandParagrphe">CGU</p>
                                    </a>
                                    <a onClick={() => {
                                        this.props.history.push('/privacy_policy');
                                        window.scrollTo(0, 0)
                                    }}>
                                        <p className="BrandParagrphe">Politique de confidentialité</p>
                                    </a>
                                </div>
                                <div className="col-4 d-flex align-items-baseline justify-content-end">
                                    <p className="sobrusLogoFooter">PROPULSÉE PAR : </p>
                                    <a href="https://sobrus.com/" target="_blank" ><img src={SobrusLogoFooter} /></a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default withRouter(Footer)