import React, { Component } from 'react'
import './ModalLayout.css'

class ModalLayout extends Component {

    render() {
        return (
            <div className="ModalLayout modal fade" id={this.props.id} role="dialog" tabindex='-1' style={this.props.style}>
                <div className={this.props.lg ? "modal-dialog modal-lg" : "modal-dialog"} >
                    <div className="modal-content">
                        <div className="ModalLayoutHeader d-flex align-items-baseline modal-header">
                            <h6>{this.props.title ? this.props.title : ''}</h6>
                            <i className="fas fa-times close" type="button" data-dismiss="modal"></i>
                        </div>
                        <div className=" modal-body px-4">
                            <div className="ModalLayoutBody">
                                <p>{this.props.bodyParagraphe ? this.props.bodyParagraphe : ''}</p>
                                {this.props.children}
                                <div className="ModalLayoutButtons d-flex">
                                    {this.props.sendBtn ? <button type="submit" className="btn btn-primary sendBtn mt-3" data-dismiss="modal" data-toggle="modal" data-target={this.props.sendBtn.dataTarget}>{this.props.sendBtn.text}</button> : ''}
                                    {this.props.cancelBtn ? <button type="button" className="btn cancelBtn navbar-btn mt-3" data-dismiss="modal">{this.props.cancelBtn.text}</button> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ModalLayout;