import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import patientsService from '../../services/patients.service'
import LogoMonpharmacien from "../../Images/Logo MonPharmacien.png"
import SobrusLogo from "../../Images/sobrus-logo.png"
import profilUser from '../../Images/profilUser.png'
import dropDownArraw from '../../Images/dropDownArraw.png'
import { LOGIN_PATIENT, SIGNUP_PATIENT, LOGIN_PHARMACIST } from '../../config'
import './Header.css'

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            authonticatedUser: null
        }
    }

    componentDidMount() {
        this.getCurrentPatient()
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ authonticatedUser: response.data })
        } catch (error) {
            console.log(error);
        }
    }

    logout = async () => {
        await patientsService.logout();
        this.setState({ authonticatedUser: null })
    }

    render() {
        return (
            <div className="Header container-fluid justify-content-center align-items-baseline">
                <div className="row justify-content-center align-items-center py-2 px-4">
                    <div className="col-4 d-flex ">
                        {/* <div className="pr-3"> */}
                        <a className="logo_link pr-2" href='/'>
                            <img src={LogoMonpharmacien} alt="monPharmacien logo" className="monpharmacien-logo " />
                        </a>
                        {/* </div> */}
                        <div className="offert_logo d-flex flex-column">
                            <span>PROPULSÉE PAR</span>
                            <a href="https://sobrus.com/" target="_blank"> <img src={SobrusLogo} alt="sobrus" /> </a>
                        </div>
                    </div>
                    <div className="col-8 d-flex align-items-center justify-content-end">
                        {!this.state.authonticatedUser && <div className="nav d-flex flex-column">
                            <a className="selected">Je suis pharmacien</a>
                            <div><i className="fas fa-sign-in-alt ml-2" style={{ color: "#00c389" }}><a onClick={() => window.open(LOGIN_PHARMACIST)} className="go_to_pro_link" >Accéder à l'espace Pro</a></i></div>
                        </div>}
                        {this.state.authonticatedUser ? <div className="d-flex user">
                            <div className="dropdown">
                                <button className="btn dropdown-toggle avatarButton" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src={profilUser} className="avatar mx-1" /><span className="avatarName mx-1">{this.state.authonticatedUser.firstName}</span><i className="fas fa-angle-down mx-1"></i>
                                </button>
                                <div className="dropdown-menu AvaterDropDownMenu" aria-labelledby="dropdownMenuButton">
                                    <img src={dropDownArraw} className="dropdown_arrow" />
                                    <a className="dropdown-item" onClick={() => this.props.history.push("/profile")} >Informations personnelles</a>
                                    <a className="dropdown-item" onClick={() => this.props.history.push("/favoritePharmacie")} >Pharmacies favorites</a>
                                    <a className="dropdown-item" onClick={() => this.props.history.push("/consultedPharmacie")}>Pharmacies consultées</a>
                                    <a className="dropdown-item" onClick={() => this.props.history.push("/prescriptionPharmacie")}>Mes ordonnances</a>

                                    <a className="dropdown-item AvaterLogout" onClick={() => this.logout()} >Déconnexion</a>
                                </div>
                            </div>
                        </div> : <div className="d-flex " style={{ marginLeft: "20px" }}>

                                <button type="button" className="btn connexion_btn" onClick={() => window.open(LOGIN_PATIENT)}>Se connecter</button>

                                <button type="button" className="btn inscription_btn" onClick={() => window.open(SIGNUP_PATIENT)}>S'inscrire</button>

                            </div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Header);