import React, { Component } from 'react';
import ModalLayout from '../../ModalLayout/ModalLayout';
import './LocalisationModal.css';

class LocalisationModal extends Component {
    render() {
        return (
            <ModalLayout title="RENSEIGNEZ LA LOCALISATION" lg={false} sendBtn={{ text: "Envoyer" }} cancelBtn={{ text: "Annuler" }} bodyParagraphe="Merci de participer à rendre l'information accessible aux patients :" id="LocalisationModal">
                <div className="LocalisationModal">
                    <div className="form-group LocalisationModalInputs my-3">
                        <label for="comment">Localisation de la pharmacie:</label>
                        <textarea type="text" name="morning_from[]" placeholder="Introduisez le lien de la localisation de votre pharmacie à partir de google Maps" className="p-2 form-control" rows="5" id="comment"></textarea>
                    </div>
                </div>
            </ModalLayout>
        );
    }
}

export default LocalisationModal;