import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import noResult from '../../Images/noResult.png'


class SearchErrourComponent extends Component {
    render() {
        return (
            <div className="SearchErrourComponent container">
                <div className="col-10 offset-1 py-5">
                    <div className="row">
                        <div className="col-8 offset-2">
                            <img src={noResult} alt="noResult" className="noResultImage" />
                            <h2 className="noResultTitle">AUCUN RÉSULTAT TROUVÉ</h2>
                            <p className="noResultParagraphe">Revérifiez le nom de la pharmacie recherchée ou cherchez par ville et par secteur</p>
                            <div className="SearchErrourComponent_buttons_group">
                                <button type="submit" className="btn btn-primary noResultButton" onClick={() => {
                                    this.props.history.push('/pharmacies')
                                    window.scrollTo(0, 0)
                                }}>Nouvelle rechreche</button>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(SearchErrourComponent)
