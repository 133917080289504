import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import AboutUs from '../../helpers/AboutUs/AboutUs';
import Footer from '../../helpers/Footer/Footer';
import Header from '../../helpers/Header/Header';
import patientsService from '../../services/patients.service';
import pharmaciesServices from '../../services/pharmacies.services';
import SearchSelect from '../../helpers/AsyncSelect'
import './LandingPage.css';
import LastPharmacies from '../../helpers/LastPharmacies/LastPharmacies';
import Loader from '../../helpers/Loader';

class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nomDeParmacie: '',
            lieuxDeParmacie: '',
            Pharmacies: [],
            currentPage: 1,
            name: "",
            searched: null,
            sectorId: null,
            limit: 9,
            selectedCity: null,
            searchedPharmacie: false,
            totalItems: null,
            authonticatedUser: null,
            loader: true,
            country: ''
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.title = "MonPharmacien - 1er annuaire interactif de pharmacies au Maroc"
        this.getCurrentPatient()
    }

    handleChange = (input) => (event) => {
        this.setState({ [input]: event.target.value });
    }


    getPatientLocalisation = async () => {
        try {
            let response = await patientsService.getPatientLocalisation()
            this.setState({ country: response.data?.countryCode ?? "ma", loader: false })
        } catch (error) {
            console.log(error);
        }
    }

    getAllPharmacies = async () => {
        const { currentPage, limit, searched, nomDeParmacie } = this.state
        try {
            let data = {
                name: nomDeParmacie,
                cityId: searched.city && searched.city.id,
                sectorId: searched.sector && searched.sector.id
            }
            let response = await pharmaciesServices.getAllPharmacies(currentPage, limit, data);
            this.setState({ Pharmacies: response.data.pharmacies, searchedPharmacie: true, totalItems: response.data.total });
        } catch (error) {
            console.error(error);
        }
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ country: response.data?.patientData?.country, loader: false })
        } catch (error) {
            this.getPatientLocalisation()
            console.log(error);
        }
    }

    handleKeyDown = (e) => {
        if (e.key === "Enter") {
            this.props.history.push({
                pathname: `/pharmacies`,
                state: {
                    selectedCity: this.state.selectedCity,
                    cityId: this.state.searched ? this.state.searched.city && this.state.searched.city.id : "",
                    sectorId: this.state.searched ? this.state.searched.sector && this.state.searched.sector.id : "",
                    pharmacieName: this.state.nomDeParmacie
                }
            })
        }
    }

    render() {
        const { searched, nomDeParmacie, selectedCity, country, loader } = this.state
        return (
            <div className="LandingPageComponent">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine" />
                </Helmet>
                {loader && <Loader />}
                <Header />
                <div className="SearchComponent">
                    <div className="container">
                        <div className="col-10 offset-1">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="text-uppercase SearchTitle">Trouvez la pharmacie la plus proche</h2>
                                    <div className="bloc_search container mt-3">
                                        <form method="get">
                                            <div className="form-row align-items-center">
                                                <div className="col-3">
                                                    <input type="text" className="form-control SearchInputs" id="name" name="name" placeholder="Nom de la pharmacie" value={this.state.nomDeParmacie} onChange={this.handleChange("nomDeParmacie")} onKeyPress={this.handleKeyDown} />
                                                </div>
                                                <div className="col-6" onKeyPress={this.handleKeyDown}>
                                                    <SearchSelect
                                                        className="form-control SearchInputs"
                                                        fetchedUrl='/cities/deep-search'
                                                        HTTPMethod="getall"
                                                        receivedObject="cities"
                                                        optionLabelProperty="name"
                                                        optionValueProperty="id"
                                                        noOptoins="Saisissez au moins un caractère"
                                                        value={this.state.selectedCity}
                                                        country={country}
                                                        placeholder="Où ? (Ville, Secteur, Code postal...)"
                                                        onChange={(e) => {
                                                            if (e) {
                                                                this.setState({ selectedCity: [], cities: [] }, () => {
                                                                    let city = {
                                                                        id: e.value
                                                                    }
                                                                    this.state.searched = e.value;
                                                                    this.setState({
                                                                        searched: e.value,
                                                                        selectedCity: [...this.state.selectedCity, e]
                                                                    }, console.log(this.state.searched))
                                                                })
                                                            }
                                                        }}
                                                        styles={{
                                                            loadingIndicator: (provided, state) => ({
                                                                ...provided,
                                                                display: "none",
                                                            }),
                                                            indicatorSeparator: (provided, state) => ({
                                                                ...provided,
                                                                display: "none",
                                                            }),
                                                            container: (provided, state) => ({
                                                                ...provided,
                                                            }),
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                borderWidth: "2px",
                                                                borderRadius: "none",
                                                                boxShadow: "none",
                                                                borderRadius: "3px",
                                                                borderColor: "#e5eef7",
                                                                height: "42px",
                                                                ":hover": {
                                                                    borderColor: "none",
                                                                    borderWidth: "2px",
                                                                },
                                                            }),
                                                            input: (provided, state) => ({
                                                                ...provided,
                                                                fontFamily: "OpenSans",
                                                                fontWeight: "600",
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                backgroundColor: state.isSelected && "#ffff",
                                                                fontFamily: "OpenSans",
                                                                borderBottom: " 1px solid #e5eef7",
                                                                padding: "5px",
                                                                color: "#52747d",
                                                                fontSize: "13px",
                                                                textAlign: 'start',
                                                                ":hover": {
                                                                    color: "#fff",
                                                                    backgroundColor: "#1bc48a",
                                                                },
                                                            }),
                                                            clearIndicator: (provided, state) => ({
                                                                ...provided,
                                                                paddingRight: 0,
                                                            }),
                                                            dropdownIndicator: (provided, state) => ({
                                                                ...provided,
                                                                paddingLeft: 0,
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <button type="button" className="btn submitSearch" onkeypres={this.handleKeyDown} id="getPharmacies"
                                                        onClick={() => this.props.history.push({
                                                            pathname: `/pharmacies`,
                                                            state: {
                                                                selectedCity: selectedCity,
                                                                cityId: searched ? searched.city && searched.city.id : "",
                                                                sectorId: searched ? searched.sector && searched.sector.id : "",
                                                                pharmacieName: nomDeParmacie
                                                            }
                                                        })}>
                                                        <p style={{ margin: "0px", paddingTop: '0.7px' }}>Chercher</p>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <LastPharmacies />
                <AboutUs />
                <Footer />
            </div>
        );
    }
}

export default LandingPage;
