import React, { Component } from 'react';
import patientsService from '../../services/patients.service';
import ModalLayout from '../ModalLayout/ModalLayout';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { IMAGES_BASE_URL_PRESCRIPRION } from '../../config';
import moment from "moment";
import "./PrescriptionPharmacieComponent.css";

class PrescriptionPharmacieComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            prescriptions: [],
            currentPage: 1,
            limit: 5,
            totalItems: null,
            collapseToOpen: 0,
            cancellationReasons: [],
            cancellationReason: null,
            lightboxIsOpenCover: false,
            selectedIndexCover: -1,
        }
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.getCancellationReasons()
        this.getAllPrescriptions()
    }

    getAllPrescriptions = async () => {
        const { currentPage, limit } = this.state
        try {
            let response = await patientsService.getAllPrescriptions(currentPage, limit);
            this.setState({ prescriptions: response.data.prescriptions, totalItems: response.data.total }, () => console.log(this.state.prescriptions))
        } catch (error) {
            console.log(error);
        }
    }
    getCancellationReasons = async () => {
        try {
            let response = await patientsService.getCancellationReasons();
            this.setState({ cancellationReasons: response.data?.cancellation_reasons })
        } catch (error) {
            console.log(error);
        }
    }

    caancelPrescrirption = async (cancellationReasonId, prescriptionId) => {
        try {
            await patientsService.caancelPrescrirption(cancellationReasonId, prescriptionId);
            this.getAllPrescriptions();
        } catch (error) {
            console.log(error);
        }
    }

    handleChange(e) {
        this.setState({ cancellationReason: e.target.value })
    }

    toggleCoverLightbox = (selectedIndexCover, lightboxIsOpenCover) => {
        this.setState({
            lightboxIsOpenCover: lightboxIsOpenCover,
            selectedIndexCover,
        });
    };

    render() {
        const { prescriptions, currentPage, totalItems, limit, cancellationReasons, cancellationReason, selectedIndexCover, lightboxIsOpenCover } = this.state
        return (
            <div className="PharmacyConsulterComponent">
                <div id="favorite-pharmacies" >
                    <table className="PharmacyConsulterTable">
                        <thead className="PharmacyConsulterThead">
                            <tr className="row">
                                <th className="col-3">Pharmacie</th>
                                <th className="col-4" style={{ paddingLeft: '0px' }}>Date d'ordonnance</th>
                                <th className="col-3"></th>
                                <th className="col-3"></th>
                            </tr>
                        </thead>
                        <tbody id="favorites" className="PrescriptionPharmacieComponentTBody">
                            {
                                prescriptions !== 0 && prescriptions.map((item, i) => {
                                    return (
                                        <>
                                            <div style={{ cursor: "pointer" }}>
                                                <tr className="row" key={i}>
                                                    <td className="col" onClick={() => {
                                                        if (this.state.collapseToOpen === item.id) {
                                                            this.setState({ collapseToOpen: 0 })
                                                        } else {
                                                            this.setState({ collapseToOpen: item.id })
                                                        }
                                                    }} >{item?.pharmacy?.name}</td>
                                                    <td className="col" onClick={() => {
                                                        if (this.state.collapseToOpen === item.id) {
                                                            this.setState({ collapseToOpen: 0 })
                                                        } else {
                                                            this.setState({ collapseToOpen: item.id })
                                                        }
                                                    }} >{moment(item?.pharmacy?.updatedAt).format("DD/MM/YYYY à h:mm")}</td>
                                                    <td className="col-4">
                                                        {/* <a className="AddFavorites" onClick={() => this.toggleFavoritePharmacies(item?.pharmacy?.slug, item.id)}>
                                                            {!item.favorite ? <><i className="fas fa-plus mr-2" /> Ajouter à mes favoris</>
                                                                : <><i className="fas fa-minus mr-2" /> Retirer de mes favoris </>}
                                                        </a> */}
                                                        <a className="viewFavorites" onClick={() => window.open(`/pharmacie/${item?.pharmacy?.slug}`)}><i className="far fa-eye mr-2"></i>Voir la pharmacie  </a>
                                                    </td>
                                                    <td className="col pt-2" onClick={() => {
                                                        if (this.state.collapseToOpen === item.id) {
                                                            this.setState({ collapseToOpen: 0 })
                                                        } else {
                                                            this.setState({ collapseToOpen: item.id })
                                                        }
                                                    }} > <a style={{ padding: "0px 3px 0px 3px" }} className={this.state.collapseToOpen === item.id ? "collapsButton" : ""}>
                                                            {this.state.collapseToOpen === item.id ? <i class="fas fa-angle-up mr-2"></i> : <i class="fas fa-angle-down mr-2"></i>}Détails
                                                        </a>
                                                    </td>
                                                </tr>
                                            </div>
                                            {
                                                this.state.collapseToOpen === item.id ? <div className="collapse_is_open d-flex justify-content-between p-2" >
                                                    <div className="Ordonnance_image_conatianer" onClick={() => this.toggleCoverLightbox(0, item.id)}>
                                                        <img src={IMAGES_BASE_URL_PRESCRIPRION + item?.pictures[0]?.pictureName} className="Ordonnance_image" />
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <p className="collapse_is_open_title">Téléphone de la pharmacie</p>
                                                            <p className="collapse_is_open_paragraphe">{item?.pharmacy?.mobileNumber ?? ""}</p>
                                                        </div>
                                                        <div>
                                                            <p className="collapse_is_open_title">Adress de la pharmacie</p>
                                                            <p className="collapse_is_open_paragraphe">SOME WHERE</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <p className="collapse_is_open_title">Ordonnance</p>
                                                            <p className="collapse_is_open_paragraphe">N° {item.id}</p>
                                                        </div>
                                                        <div>
                                                            <p className="collapse_is_open_title">Status de l'ordonnance</p>
                                                            <p className={item.status === "Livrée" ? "livButton" : item.status === "Nouvelle" ? "nouButton" : item.status === "En cours" ? "encButton" : item.status === "Prétes" ? "preButton" : item.status === "Annulée" ? "annButton" : "preButton"}>{item.status}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-end">
                                                        {item.status === "Livrée" || item.status === "Annulée" ? "" : <p className="prscreption_cancel" data-toggle="modal" data-target="#cancellationReasons" >Annuler l'ordonnance</p>}
                                                        <ModalLayout title="Raison d’annulation" lg={false} cancelBtn={{ text: "Annuler" }} bodyParagraphe="Pourquoi souhaitez-vous annuler votre ordonnance ?" id="cancellationReasons">
                                                            <div className="form-group SecteurModalInputs">
                                                                <label for="sel1">Raison d’annulation :</label>
                                                                <select className="form-control" id="sel1" name="sellist1" onChange={this.handleChange} value={cancellationReason}>
                                                                    <option></option>
                                                                    {
                                                                        cancellationReasons.map((item, key) => {
                                                                            return <option value={item?.id} key={key}>{item?.reason}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            <button className="btn-primary sendBtn mt-3 w-100" data-dismiss="modal" data-toggle="modal" onClick={() => this.caancelPrescrirption(cancellationReason, item.id)} >Envoyer</button>
                                                        </ModalLayout>
                                                    </div>
                                                </div> : ""
                                            }
                                            <ModalGateway>
                                                {lightboxIsOpenCover === item.id ? (<Modal onClose={this.toggleCoverLightbox}>
                                                    <Carousel
                                                        // components={{ FooterCaption }}
                                                        currentIndex={selectedIndexCover}
                                                        // formatters={{ getAltText }}
                                                        views={[{
                                                            source: IMAGES_BASE_URL_PRESCRIPRION + item?.pictures[0]?.pictureName,
                                                            caption: item.pictureTitle
                                                        }]}
                                                    />
                                                </Modal>) : null}
                                            </ModalGateway>

                                        </>
                                    )
                                })
                            }
                            <div className="PharmacyConsulterPagination">
                                <div className="Paginationbutton d-flex align-content-center">
                                    <ul className="pagination">
                                        <li className="page-item">
                                            {currentPage > 1 && (
                                                <a className="page-link" onClick={() => this.setState({
                                                    currentPage: currentPage - 1
                                                }, () => this.getAllPrescriptions())} aria-label="Previous">
                                                    <span aria-hidden="true"><i className="fas fa-chevron-left pagination_icon"></i></span>
                                                </a>
                                            )}
                                        </li>
                                        <li className="page-item"><a className="page-link">{currentPage}</a></li>
                                        <li className="page-item">
                                            {
                                                currentPage < totalItems / limit && (
                                                    <a className="page-link" onClick={() => this.setState({
                                                        currentPage: currentPage + 1
                                                    }, () => this.getAllPrescriptions())
                                                    } aria-label="Previous">
                                                        <span aria-hidden="true"><i className="fas fa-chevron-right pagination_icon"></i></span>
                                                    </a>)
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default PrescriptionPharmacieComponent;