import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MANAGE_PROFILE_URL } from '../../config';
import './InfoPersonnelComponent.css';

class InfoPersonnelComponent extends Component {
    render() {
        const { authonticatedUser } = this.props;
        return (
            <>
                <div className="InfoPersonnelComponent mb-4">
                    <p className="InfoPersonnelComponent_title">Informations générales</p>
                    <div className=" d-flex row" id="personal-informations" >
                        <div className="col-6 InfoPersonnel">
                            <div>
                                <strong className="mr-1">Nom : </strong>
                                <p>{authonticatedUser && authonticatedUser.lastName}</p>
                            </div>
                            <div>
                                <strong className="mr-1">Prénom : </strong>
                                <p>{authonticatedUser && authonticatedUser.firstName}</p>
                            </div>
                        </div>
                        <div className="col-6 InfoPersonnel">
                            <div>
                                <strong className="mr-1">Email : </strong>
                                <p>{authonticatedUser && authonticatedUser.email}</p>
                            </div>
                            <div>
                                <strong className="mr-1">Téléphone : </strong>
                                <p>{authonticatedUser && authonticatedUser.mobileNumber}</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary InfoPersonnelButton" onClick={() => window.open(MANAGE_PROFILE_URL)}>Gérer mon compte</button>
                    </div>
                </div>
                <div className="InfoPersonnelComponent">
                    <p className="InfoPersonnelComponent_title">Adresse</p>
                    <div className=" d-flex row" id="personal-informations" >
                        <div className="col-6 InfoPersonnel">
                            <div>
                                <strong className="mr-1">Ville : </strong>
                                <p>{authonticatedUser && authonticatedUser?.patientData?.city?.name}</p>
                            </div>
                            <div>
                                <strong className="mr-1">Pays : </strong>
                                <p>{authonticatedUser && authonticatedUser?.patientData?.country === "dj" ? "Djibouti" :
                                    authonticatedUser?.patientData?.country === "ma" ? "Maroc" :
                                        authonticatedUser?.patientData?.country === "sn" ? "Sénégal" : ""}</p>
                            </div>
                        </div>
                        <div className="col-6 InfoPersonnel">
                            {authonticatedUser && authonticatedUser?.patientData?.country === "ma" && <div>
                                <strong className="mr-1">Secteur : </strong>
                                <p>{authonticatedUser && authonticatedUser?.patientData?.sector?.name}</p>
                            </div>}
                            {authonticatedUser && authonticatedUser?.patientData?.country !== "ma" && <div>
                                <strong className="mr-1">Code postal : </strong>
                                <p>{authonticatedUser && authonticatedUser?.patientData?.zipcode}</p>
                            </div>}
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Link to='/profile/edit'>
                            <button type="button" className="btn btn-primary InfoPersonnelButton">Modifier</button>
                        </Link>
                    </div>
                </div>
            </>
        );
    }
}

export default InfoPersonnelComponent;