import React, { Component } from 'react';
import patientsService from '../../../services/patients.service';
import pharmaciesServices from '../../../services/pharmacies.services';
import ModalLayout from '../../ModalLayout/ModalLayout';
import './PrprietaireModal.css';

class PrprietaireModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startOfActivityDate: null,
            pharmacistId: null,
            pharmacyId: null,
            startOfActivityDateErrors: null
        }
    }

    handleChange = (e) => {
        this.setState({ startOfActivityDate: e.target.value });
    };

    handleValidation = async () => {
        const { startOfActivityDate } = this.state;
        let formIsValid = true;
        let error = null
        if (startOfActivityDate === null) {
            formIsValid = false;
            error = "Date de début d'activité est obligatoire";
        }
        this.setState({ startOfActivityDateErrors: error });
        return formIsValid;
    };

    seggestOwnerShip = async () => {
        this.setState({ startOfActivityDateErrors: null }, async () => {
            let valid = await this.handleValidation();
            if (valid) {
                console.log(this.props.pharmacistId + " " + this.props.pharmacyId)
                try {
                    const data = {
                        pharmacyOwnershipSuggestion: {
                            startOfActivityDate: this.state.startOfActivityDate,
                            pharmacyId: this.props.pharmacyId,
                            pharmacistId: this.props.pharmacistId
                        }
                    }
                    await pharmaciesServices.seggestOwnerShip(data)
                } catch (error) {
                    console.log(error);
                }
            }
        });
    }


    render() {
        const { startOfActivityDate, startOfActivityDateErrors } = this.state
        return (
            <ModalLayout title="DEMANDE DE PROPRIÉTAIRE" lg={false} cancelBtn={{ text: "Annuler" }} id="PrprietaireModal">
                <div className="PrprietaireModalBody ">
                    <div className="form-group">
                        <label className=" LoginFormLabels">Date de début d'activité :</label>
                        <input type="date" className="form-control LoginFormInputs" id="exampleInputPassword1" onChange={this.handleChange} value={startOfActivityDate} onKeyPress={this.handleKeyDown} />
                        {startOfActivityDateErrors && <p>{startOfActivityDateErrors}</p>}
                    </div>
                    <button type="button" className="btn btn-primary LoginFormButton" onClick={() => this.seggestOwnerShip()}>Confirmer</button>
                </div>
            </ModalLayout>
        );
    }
}

export default PrprietaireModal;