import React, { Component } from 'react';
import Footer from '../../helpers/Footer/Footer';
import Header from '../../helpers/Header/Header';
import noResult from '../../Images/noResult.png'
import './SearchErrourPage.css'

class SearchErrourPage extends Component {
    render() {
        return (
            <div className="SearchErrourPageComponent">
                <Header />
                <div className="SearchRComponent">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 col-md-12 col-sm-12 offset-lg-1">
                                <div className="bloc_search container p-3 mt-3">
                                    <form method="get">
                                        <div className="form-row align-items-center">
                                            <div className="col-3">
                                                <input type="text" className="form-control SearchInputs" id="name" name="name" placeholder="Nom de la pharmacie" />
                                            </div>
                                            <div className="col-6">
                                                <input type="text" className="form-control SearchInputs" id="name" name="name" placeholder="Où? (Ville, quartier)" />

                                            </div>
                                            <div className="col-3">
                                                <button type="submit" className="btn btn-success submitSearch " id="getPharmacies">Chercher</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="bloc_search_ombre">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="SearchErrourComponent container">
                    <div className="col-10 offset-1 py-5">
                        <div className="row">
                            <div className="col-8 offset-2">
                                <img src={noResult} alt="noResult" className="noResultImage" />
                                <h2 className="noResultTitle">AUCUN RÉSULTAT TROUVÉ</h2>
                                <p className="noResultParagraphe">Revérifiez le nom de la pharmacie recherchée ou cherchez par ville et par secteur</p>
                                <div className="SearchErrourComponent_buttons_group">
                                    <button type="submit" className="btn btn-primary noResultButton" onClick={() => {
                                        this.props.history.push('/pharmacies')
                                        window.scrollTo(0, 0)
                                    }}>Nouvelle rechreche</button>
                                    {/* <div className="search_result_ou">
                                        <span className="search_result_ou_span_right"></span>
                                        <p className="search_result_ou_span_text">OU</p>
                                        <span className="search_result_ou_span_left"></span>
                                    </div>
                                    <button type="submit" className="btn btn-primary SuggérerResultButton" onClick={() => {
                                        this.props.history.push('')
                                        window.scrollTo(0, 0)
                                    }}>Suggérer une pharmacie</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default SearchErrourPage;
