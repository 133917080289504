import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import favoritePharmaciesService from '../../services/favoritePharmacies.service';
import './PharmacyFavoritComponent.css';

class PharmacyFavoritComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            favoritePharmacies: [],
            currentPage: 1,
            limit: 5,
            totalItems: null,
            removeAnimation: false,
            removeAnimationId: null
        }
    }

    componentDidMount() {
        this.getFavoritePharmacies()
    }

    getFavoritePharmacies = async () => {
        const { currentPage, limit } = this.state
        try {
            let response = await favoritePharmaciesService.getFavoritePharmacies(currentPage, limit);
            this.setState({ favoritePharmacies: response.data.pharmacies, totalItems: response.data.total, removeAnimation: false })
        } catch (error) {
            console.log(error);
        }
    }

    toggleFavoritePharmacies = async (slug, id) => {
        try {
            this.setState({ removeAnimation: true })
            await favoritePharmaciesService.toggleFavoritePharmacies(slug);
            this.setState({ removeAnimation: true, removeAnimationId: id }, () => setTimeout(() => {
                this.getFavoritePharmacies()
            }, 1000))
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { favoritePharmacies, currentPage, limit, totalItems, removeAnimation, removeAnimationId } = this.state
        return (
            <div className="PharmacyFavoritComponent">
                <div id="favorite-pharmacies" className="ui-tabs-panel ui-corner-bottom ui-widget-content" >
                    <table className="PharmacyFavoritTable">
                        <thead className="PharmacyFavoritThead">
                            <tr className="row">
                                <th className="col-4">Pharmacie</th>
                                <th className="col-4">Ville</th>
                                <th className="col-4"></th>
                            </tr>
                        </thead>
                        <tbody id="favorites" className="PharmacyFavoritTBody">
                            {
                                favoritePharmacies !== 0 && favoritePharmacies.map((item, i) => {
                                    return (
                                        <tr className="row" key={i} id={removeAnimation && (removeAnimationId === item.id) ? "box" : ""}>
                                            <td className="col-4">{item.name}</td>
                                            <td className="col-4">{item.city && item.city.name}</td>
                                            <td className="col-4 d-flex flex-column align-items-baseline">
                                                <a className="deleteFavorites" onClick={() => this.toggleFavoritePharmacies(item?.slug, item.id)}><i className="fas fa-minus mr-2"></i>Retirer de mes favoris </a>
                                                <a className="viewFavorites" onClick={() => window.open(`/pharmacie/${item.slug}`)}><i className="far fa-eye mr-2"></i>Voir la pharmacie</a>
                                            </td>
                                        </tr>)
                                })
                            }
                            <div className=" PharmacyFavoritPagination">
                                <div className="Paginationbutton d-flex align-content-center">
                                    <ul className="pagination">
                                        <li className="page-item">
                                            {currentPage > 1 && (
                                                <a className="page-link" onClick={() => this.setState({
                                                    currentPage: currentPage - 1
                                                }, () => this.getFavoritePharmacies())} aria-label="Previous">
                                                    <span aria-hidden="true"><i className="fas fa-chevron-left pagination_icon"></i></span>
                                                </a>
                                            )}
                                        </li>
                                        <li className="page-item"><a className="page-link">{currentPage}</a></li>
                                        <li className="page-item">
                                            {
                                                currentPage < totalItems / limit && (
                                                    <a className="page-link" onClick={() => this.setState({
                                                        currentPage: currentPage + 1
                                                    }, () => this.getFavoritePharmacies())
                                                    } aria-label="Previous">
                                                        <span aria-hidden="true"><i className="fas fa-chevron-right pagination_icon"></i></span>
                                                    </a>)
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withRouter(PharmacyFavoritComponent);