import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom'
import favoritePharmaciesService from '../../services/favoritePharmacies.service';
import patientsService from '../../services/patients.service';
import pharmaciesServices from '../../services/pharmacies.services';
import AdresseModal from '../Models/AdresseModal/AdresseModal';
import DeplomeModal from '../Models/DeplomeModal/DeplomeModal';
import HorairesModel from '../Models/HorairesModel/HorairesModel';
import LanguegeModel from '../Models/LanguegeModel/LanguegeModel';
import LocalisationModal from '../Models/LocalisationModal/LocalisationModal';
import LoginModal from '../Models/LoginModal/LoginModal';
import MoyensDePaiementModel from '../Models/MoyensDePaiementModel/MoyensDePaiementModel';
import PharmacieModel from '../Models/PharmacieModel/PharmacieModel';
import PharmacienModal from '../Models/PharmacienModal/PharmacienModal';
import PhoneNumberModal from '../Models/PhoneNumberModa/PhoneNumberModa';
import PrprietaireModal from '../Models/PrprietaireModal/PrprietaireModal';
import SecteurModal from '../Models/SecteurModal/SecteurModal';
import PhotoGalerieRempliComponent from '../PhotoGalerieRempliComponent/PhotoGalerieRempliComponent';
import moment from 'moment';
import Share from '../Share/Share';
import coverEmpty from '../../Images/coverEmpty.png';
import pdpEmpty from '../../Images/pdpEmpty.png';
import spainFlag from '../../Images/spainFlag.png';
import Marocflags from '../../Images/Marocflags.png';
import Franceflags from '../../Images/Franceflags.png';
import UKflags from '../../Images/UKflags.png';
import ItalyFlag from '../../Images/ItalyFlag.png';
import CarteBancaire from '../../Images/CarteBancaire.png';
import Wire_Transfer from '../../Images/Wire Transfer.png';
import Cheque from '../../Images/Cheque.png';
import Especes from '../../Images/Espèces.png';
import Thanks from '../../Images/thanks.png';
import ButtonImage from '../../Images/ButtonImage.png';
import Other from '../../Images/Other.png';
import Bill_of_Exchange from '../../Images/Bill of Exchange.png';
import Credit from '../../Images/Credit.png';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Header from '../Header/Header';
import position_icon from '../../Images/position_icon.png';
import Allemand from '../../Images/Allemand.png'
import Chinois from '../../Images/Chinois.png'
import Japonais from '../../Images/Japonais.png'
import Portugais from '../../Images/Portugais.png'
import Russe from '../../Images/Russe.png'
import Turc from '../../Images/Turc.png'
import Ukrainien from '../../Images/Ukrainien.png'
import Wolof from '../../Images/Wolof.png'
import Amazigh from '../../Images/Amazigh.png'
import Rifain from '../../Images/Rifain.png'
import GoogleMapReact from 'google-map-react';
import { IMAGES_BASE_URL, LINK_PHARMA_PRO } from '../../config';
import './PharmacieInfoComponent.css';
import 'leaflet/dist/leaflet.css';
import 'moment/locale/fr';


const openingHoursOfnull = [{ id: 1, dayOfWeek: "Lundi", startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 2, dayOfWeek: "Mardi", startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 3, dayOfWeek: "Mercredi", startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 4, dayOfWeek: "Jeudi", startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 5, dayOfWeek: "Vendredi", startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 6, dayOfWeek: "Samedi", startOne: "09:00", endOne: "13:00" }]

const gutter = 2;

const Gallery = (props) => (
    <div className="row" style={{ marginLeft: -gutter, marginRight: -gutter }} {...props} />
);

const Image = (props) => (
    <div {...props} />
);

const AnyReactComponent = ({ text }) => <div className="map_marker_container"><i class="fas fa-map-marker-alt"></i> {text}  </div>;

class PharmacieInfoComponent extends Component {
    constructor() {
        super()
        this.state = {
            active: 1,
            recommendationData: {
                ...this.recommendationDataFields
            },
            date: new Date(),
            pharmacieData: null,
            show: false,
            FavoritePharmacies: false,
            authonticatedUser: null,
            recommendationDesplay: 0,
            productAvailabilityScore: null,
            staffCourtesyScore: null,
            pharmacyLayoutScore: null,
            pharmacyId: null,
            showHoraire: false,
            today: "",
            isRecommended: false,
            position: [30, -6],
            visible: false,
            slug: null,
            openingHours: [],
            photoDeCover: [],
            photoDeProfile: [],
            photoDePharmacy: [],
            lightboxIsOpen: false,
            selectedIndex: 0,
            lightboxIsOpenCover: false,
            selectedIndexCover: 0,
            noFav: false,
            AddrecommendedPharmaciesErrors: false,
            availablePaymentMethodsPrepared: [],
            openingHoursClose: [],
            openingHoursOpen: [],
            linked: false
        }
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }

    recommendationDataFields = {
        productAvailabilityScore: 0,
        staffCourtesyScore: 0,
        pharmacyLayoutScore: 0,
        status: true
    }

    componentDidMount() {
        const slug = this.props.match && this.props.match.params.slug;
        this.setState({ slug })
        const link = `/pharmacie/${slug}`
        document.cookie = "link =" + JSON.stringify(link)
        this.getPharmacieByslug(slug);
        this.getCurrentPatient();
        const today = moment().locale('fr').format('dddd');
        this.setState({ today })
    }

    componentDidUpdate() {
        const slug = this.props.match && this.props.match.params.slug;
        if (this.state.slug !== slug) {
            this.setState({ slug })
            this.getPharmacieByslug(slug);
        }
    }

    handleOptionChange = (changeEvent) => {
        this.setState({
            productAvailabilityScore: changeEvent.target.value
        }, () => console.log(this.state.productAvailabilityScore));
    }

    staffHandleOptionChange = (changeEvent) => {
        this.setState({
            staffCourtesyScore: changeEvent.target.value
        }, () => console.log(this.state.staffCourtesyScore));
    }

    pharmacyHandleOptionChange = (changeEvent) => {
        this.setState({
            pharmacyLayoutScore: changeEvent.target.value
        }, () => console.log(this.state.pharmacyLayoutScore));
    }

    handleChange = (input) => (e) => {
        let recommendationDataCopy = JSON.parse(JSON.stringify(this.state.recommendationData));
        recommendationDataCopy[input] = e.target.value;
        this.setState({ recommendationData: recommendationDataCopy });
    };

    getPharmacieByslug = async (slug) => {
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            const action = {
                action: "Consultation"
            }
            const filePrincToUpdate = []
            const photoDeCover = []
            const photoDeProfile = []
            if (response.data.pharmacy?.pictures?.length !== 0) {
                response.data.pharmacy?.pictures.map(async (item, key) => {
                    if (item?.pictureType === "pharmacy" || item?.pictureType === "cover") {
                        filePrincToUpdate.push(item)
                        this.setState({ photoDePharmacy: filePrincToUpdate })
                    }
                    if (item?.pictureType === "cover") {
                        photoDeCover.push(item)
                        this.setState({ photoDeCover })
                    } else if (item?.pictureType === "pharmacist") {
                        photoDeProfile.push(item)
                        this.setState({ photoDeProfile })
                    }
                })
            } else {
                this.setState({ photoDePharmacy: filePrincToUpdate, photoDeCover, photoDeProfile })
            }
            this.setState({
                pharmacieData: response.data.pharmacy,
                FavoritePharmacies: response.data.favorite,
                pharmacyId: response.data.pharmacy.id,
                isRecommended: response.data.recommended,
                openingHours: response.data.pharmacy?.openingHours.length !== 0 ? response.data.pharmacy?.openingHours : openingHoursOfnull,
                linked: response.data.linked
            }, () => {
                if (response.data.pharmacy?.openingHours.length !== 0) {
                    let openingHours = null
                    let openingHoursArray = []
                    for (let index = 0; index < this.state.openingHours.length; index++) {
                        openingHours = {
                            dayOfWeek: this.state.openingHours[index]?.dayOfWeek === 0 ? "Lundi" : this.state.openingHours[index]?.dayOfWeek === 1 ? "Mardi" :
                                this.state.openingHours[index]?.dayOfWeek === 2 ? "Mercredi" : this.state.openingHours[index]?.dayOfWeek === 3 ? "Jeudi" :
                                    this.state.openingHours[index]?.dayOfWeek === 4 ? "Vendredi" : this.state.openingHours[index]?.dayOfWeek === 5 ? "Samedi"
                                        : this.state.openingHours[index]?.dayOfWeek === 6 ? "Dimanche" : "",
                            endOne: this.state.openingHours[index]?.endOne,
                            endTwo: this.state.openingHours[index]?.endTwo,
                            id: this.state.openingHours[index]?.id,
                            startOne: this.state.openingHours[index]?.startOne,
                            startTwo: this.state.openingHours[index]?.startTwo
                        }
                        openingHoursArray.push(openingHours)
                    }
                    this.preparOpeningHoursData(openingHoursArray)
                } else {
                    this.preparOpeningHoursData(this.state.openingHours)
                }
                this.ActionOfClientInPharmacie(this.state.pharmacieData?.slug, action)
                if (this.state.pharmacieData?.gpsLocation) {
                    const position = this.state.pharmacieData?.gpsLocation.split(' ');
                    let positionNet = []
                    positionNet = position.map(element => {
                        return parseFloat(element)
                    });
                    this.setState({ position: [...positionNet] })
                }
                if (this.state.pharmacieData?.availablePaymentMethods !== 0) {
                    console.log("availablePaymentMethods", this.state.pharmacieData?.availablePaymentMethods);
                    if (this.state.pharmacieData?.availablePaymentMethods?.some((el) => el?.name === "other")) {
                        let availablePaymentMethodsPrepared = [];
                        let availablePaymentMethodsPreparedrest = [];
                        let lastOne = []
                        this.state.pharmacieData?.availablePaymentMethods?.map((item) => {
                            console.log('item', item);
                            if (item?.name !== "other") {
                                availablePaymentMethodsPreparedrest?.push(item)
                                console.log(availablePaymentMethodsPreparedrest);
                            } else {
                                lastOne.push(item)
                            }
                        })
                        availablePaymentMethodsPrepared = availablePaymentMethodsPreparedrest.concat(lastOne)
                        console.log('availablePaymentMethodsPrepared', availablePaymentMethodsPrepared);
                        this.setState({ availablePaymentMethodsPrepared })
                    } else {
                        this.setState({ availablePaymentMethodsPrepared: this.state.pharmacieData?.availablePaymentMethods })
                    }
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ authonticatedUser: response.data })
        } catch (error) {
            console.log(error);
        }
    }

    ActionOfClientInPharmacie = async (slug, Action) => {
        try {
            let response = await patientsService.clientAddActionToPharmacies(slug, Action)
        } catch (error) {
            console.log(error);
        }
    }

    handleRecommendedPharmaciesValidation = async () => {
        const { staffCourtesyScore, productAvailabilityScore, pharmacyLayoutScore } = this.state;
        let formIsValid = true;

        if (pharmacyLayoutScore === null || staffCourtesyScore === null || productAvailabilityScore === null) {
            formIsValid = false;
            this.setState({ AddrecommendedPharmaciesErrors: true });
        }

        return formIsValid;
    };

    AddrecommendedPharmacies = async (slug) => {
        this.setState({ AddrecommendedPharmaciesErrors: false }, async () => {
            let valid = await this.handleRecommendedPharmaciesValidation();
            if (valid) {
                try {
                    let copyRecommendationData = this.state.recommendationData
                    copyRecommendationData = {
                        ...this.state.recommendationData,
                        productAvailabilityScore: this.state.productAvailabilityScore,
                        staffCourtesyScore: this.state.staffCourtesyScore,
                        pharmacyLayoutScore: this.state.pharmacyLayoutScore,
                        status: true
                    }
                    await patientsService.AddrecommendedPharmacies(slug, copyRecommendationData)
                    this.setState({ recommendationDesplay: 2 })
                } catch (error) {
                    console.log(error);
                }
            }
        });
    }

    toggleFavoritePharmacies = async (slug) => {
        try {
            await favoritePharmaciesService.toggleFavoritePharmacies(slug)
            this.setState({ FavoritePharmacies: true })
        } catch (error) {
            if (400 === error?.response?.status) {
                if (error.response.data.message === "you have already favored 3 pharmacies") {
                    window.scrollTo(0, 0);
                    this.setState({ noFav: true })
                    this.emailNotefitacion()
                }
            }
        }
    }

    toggleLightbox = (selectedIndex) => {
        this.setState((state) => ({
            lightboxIsOpen: !state.lightboxIsOpen,
            selectedIndex,
        }));
    };

    toggleCoverLightbox = (selectedIndexCover) => {
        this.setState((state) => ({
            lightboxIsOpenCover: !state.lightboxIsOpenCover,
            selectedIndexCover,
        }));
    };

    emailNotefitacion() {
        setTimeout(() => {
            this.setState({ noFav: false })
        }, 2000);
    }

    preparOpeningHoursData(openingHours) {
        let pos = openingHours.findIndex((el) => el.dayOfWeek === (this.state.today.charAt(0).toUpperCase() + this.state.today.slice(1)));
        let openingHoursClose = [];
        let openingHoursOpen = [];

        for (let index = pos; index < openingHours.length; index++) {
            if (openingHours[index]) {
                openingHoursClose.push(openingHours[index])
            }
        }

        for (let index = 0; index < pos; index++) {
            if (openingHours[index]) {
                openingHoursOpen.push(openingHours[index])
            }
        }

        const array3 = openingHoursClose.concat(openingHoursOpen);

        this.setState({ openingHoursOpen: array3 })
    }

    render() {
        const { photoDePharmacy, noFav, lightboxIsOpenCover, selectedIndexCover, photoDeCover,
            photoDeProfile, active, pharmacieData, authonticatedUser, recommendationDesplay,
            showHoraire, isRecommended, position, openingHours, lightboxIsOpen, selectedIndex,
            AddrecommendedPharmaciesErrors, availablePaymentMethodsPrepared, openingHoursClose,
            openingHoursOpen, linked } = this.state;
        const image = photoDeProfile.map((item, j) => {
            return {
                source: IMAGES_BASE_URL + item?.pictureRef,
                caption: item.pictureTitle,
            };
        });
        const imageCover = photoDeCover.map((item, j) => {
            return {
                source: IMAGES_BASE_URL + item?.pictureRef,
                caption: item.pictureTitle,
            };
        });
        return (
            <div>
                {
                    noFav && <div className="sign_up_notification" style={{ backgroundColor: "#f6ac32" }}>
                        <i class="fas fa-exclamation-triangle"></i>
                        <p>Vous avez atteint le nombre maximum(3) des pharmacies favorites</p>
                    </div>
                }
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>{`${pharmacieData && pharmacieData?.name} sur MonPharmacien - 1er annuaire interactif de pharmacies au Maroc`}</title>
                    <meta name="description" content={`Cette page représente ${pharmacieData && pharmacieData?.name} qui se trouve à ${pharmacieData?.city ? pharmacieData?.city?.name : ''} (${pharmacieData?.sector ? pharmacieData?.sector?.name : ''}),
                     à l’adresse ${pharmacieData && pharmacieData.address}. Cette pharmacie est gérée par Dr. ${pharmacieData?.pharmacist ? (pharmacieData.pharmacist?.firstName + " " + pharmacieData.pharmacist?.lastName) : ''}`} />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content={` Pharmacie de garde, Pharmacie à ${pharmacieData?.city ? pharmacieData?.city?.name : ''}, Pharmacie à ${pharmacieData?.sector ? pharmacieData?.sector?.name : ''}, 
                    ${pharmacieData && pharmacieData.name}, ${pharmacieData?.pharmacist ? (pharmacieData.pharmacist?.firstName + " " + pharmacieData.pharmacist?.lastName) : ''}`} />
                </Helmet>
                <Header />
                <div className="pharmacieInfoComponent ">
                    <div className="pharmacieInfoHeader">
                    </div>
                    <div className="container pharmacieInfoCorp mb-5">
                        <div className="col-10 offset-1">
                            <div className="row ">
                                <div className="pharmacieInfoprofil col-12">
                                    <div>
                                        <Gallery className="cover">
                                            {
                                                photoDeCover.length !== 0 ?
                                                    <Image onClick={() => this.toggleCoverLightbox(0)} >
                                                        <img src={IMAGES_BASE_URL + photoDeCover[0]?.pictureRef} title={pharmacieData && pharmacieData?.name} className="CoverImage" />
                                                    </Image> : <img src={coverEmpty} className="CoverImage" title={pharmacieData && pharmacieData?.name} />
                                            }
                                        </Gallery>
                                    </div>
                                    <ModalGateway>
                                        {lightboxIsOpenCover ? (
                                            <Modal onClose={this.toggleCoverLightbox}>
                                                <Carousel
                                                    // components={{ FooterCaption }}
                                                    currentIndex={selectedIndexCover}
                                                    // formatters={{ getAltText }}
                                                    views={imageCover}
                                                />
                                            </Modal>
                                        ) : null}
                                    </ModalGateway>
                                    <div className="profileInformations d-flex ">
                                        <div className="PhotoDeProfil">
                                            <Gallery style={{ height: "100%" }}>
                                                {
                                                    photoDeProfile.length !== 0 ?
                                                        <Image onClick={() => this.toggleLightbox(0)} >
                                                            <img src={IMAGES_BASE_URL + photoDeProfile[0]?.pictureRef} title={pharmacieData && pharmacieData?.name} className="suggest_pharmacist_picture" />
                                                        </Image> : <img src={pdpEmpty} className="suggest_pharmacist_picture" title={pharmacieData && pharmacieData?.name} />
                                                }
                                            </Gallery>
                                        </div>
                                        <ModalGateway>
                                            {lightboxIsOpen ? (
                                                <Modal onClose={this.toggleLightbox}>
                                                    <Carousel
                                                        // components={{ FooterCaption }}
                                                        currentIndex={selectedIndex}
                                                        // formatters={{ getAltText }}
                                                        views={image}
                                                    />
                                                </Modal>
                                            ) : null}
                                        </ModalGateway>
                                        <div className="ProfileInfo">
                                            <div className="AddTofavoris_component">
                                                <h2 className="pharmacyName">{pharmacieData && pharmacieData.name}</h2>
                                                <>{
                                                    this.state.FavoritePharmacies ? <div className="AddedTofavoris_component_two"><i class="fas fa-star AddedTofavoris"></i><p>Pharmacie favorite</p></div>
                                                        :
                                                        <div className="AddTofavoris_component_two" onPointerEnter={
                                                            () => this.setState({ show: true })
                                                        }
                                                            data-toggle="modal" data-target={authonticatedUser ? "" : "#LoginModal"} onClick={() => authonticatedUser && this.toggleFavoritePharmacies(pharmacieData && pharmacieData.slug)}
                                                        ><i class="far fa-star AddTofavoris"></i>{!this.state.show ? "" : <p>Ajouter à mes favoris</p>}</div>
                                                }
                                                </>

                                            </div>
                                            {pharmacieData?.showPharmacistName && <p><strong>Nom pharmacien : </strong>{pharmacieData && pharmacieData.pharmacist ? pharmacieData.pharmacist.firstName + " " + pharmacieData.pharmacist.lastName : ""} </p>}
                                            {pharmacieData?.showPharmacistDegree && <p><strong>Diplôme : </strong>{pharmacieData && (pharmacieData.pharmacist?.degreeSchool || pharmacieData?.pharmacist?.degreeYear) ? ((pharmacieData.pharmacist?.degreeSchool ?? '') + ', ' + (pharmacieData?.pharmacist?.degreeYear ?? '')) : ""}</p>}
                                        </div>
                                    </div>
                                    <div className="navigationTab ">
                                        <ul className="d-flex mb-0 ">
                                            <li data-tab="informations " className={active === 1 ? "iSactive" : " pb-3"} onClick={() => this.setState({ active: 1 })}>Informations</li>
                                            <li data-tab="photos" className={active === 2 ? "iSactive ml-5 pb-3" : "ml-5 pb-3"} onClick={() => this.setState({ active: 2 })}>Photos</li>
                                            {/* <li className="newSuggestion pb-3" data-toggle="modal" data-target={authonticatedUser ? "#SuggestionModel" : "#LoginModal"}> <i className="fas fa-pencil-alt mr-2 "></i>Suggérer une modification</li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-8 pl-0">
                                    {active === 1 ?
                                        <>
                                            <div className="BlocInformationsRempli">
                                                <div id="informations" className="current-tab px-4">
                                                    <div className="row_about">
                                                        <i className="fas fa-phone-alt mr-2" style={{ fontSize: "12px" }}></i>
                                                        <p className="row_about_title" style={{ marginLeft: "9px" }}>Téléphone : <span> {pharmacieData && pharmacieData.phone ? pharmacieData.phone.slice(0, 4) + '...' : " "}
                                                        </span><a className="lien_add suggest_phone" data-toggle="modal" data-target="#PhoneNumberModal" onClick={() => {
                                                            const action = {
                                                                action: "Call"
                                                            }
                                                            this.ActionOfClientInPharmacie(pharmacieData?.slug, action)
                                                        }}> Afficher le téléphone</a>
                                                        </p>
                                                    </div>
                                                    <div className="row_about py-2">
                                                        <i className="fas fa-language mr-2"></i>
                                                        <div className="langues_ligne">
                                                            <p className="row_about_title">Langues parlées à la pharmacie : {pharmacieData?.spokenLanguages.length === 0 && " " + " -"}</p>
                                                            <div className="languages d-flex row ">
                                                                {
                                                                    pharmacieData?.spokenLanguages.length !== 0 ?
                                                                        pharmacieData?.spokenLanguages.map((item) => {
                                                                            return <div className="language d-flex align-items-baseline px-3 col-4">
                                                                                <img style={{ maxWidth: "20px" }} src={item?.name === "Arabe" ? Marocflags : item?.name === "Allemand" ? Allemand : item?.name === "Russe" ? Russe
                                                                                    : item?.name === "Français" ? Franceflags : item?.name === "Anglais" ? UKflags : item?.name === "Espagnol" ? spainFlag
                                                                                        : item?.name === "Italien" ? ItalyFlag : item?.name === "Ukrainien" ? Ukrainien : item?.name === "Portugais" ? Portugais
                                                                                            : item?.name === "Chinois" ? Chinois : item?.name === "Japonais" ? Japonais : item?.name === "Turc" ? Turc
                                                                                                : item?.name === "Wolof (Sénégal)" ? Wolof : item?.name === "Amazigh" ? Amazigh : item?.name === "Rifain" ? Rifain : ''} />
                                                                                <p className="px-1">{item?.name}</p>
                                                                            </div>
                                                                        }) : <p></p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="RowInformtaions py-2">
                                                        <div className="d-flex align-items-baseline">
                                                            <i className="fas fa-hand-holding-medical mr-2"></i>
                                                            <p className="RowInformtaionsTitle"> Services proposés par la pharmacie : {pharmacieData?.availableServices.length === 0 && " " + " -"}</p>
                                                        </div>
                                                        <div className="d-flex row">
                                                            {
                                                                pharmacieData?.availableServices.map((item, key) => {
                                                                    return <div className="services col-6" key={key}> <div className="service d-flex align-items-baseline">
                                                                        <i className="fas fa-check mr-2" style={{ fontSize: "11px" }}></i>
                                                                        <p>{item?.name}</p>
                                                                    </div>
                                                                    </div>
                                                                }) ?? <p></p>
                                                            }
                                                        </div>
                                                    </div>


                                                    <div className="RowInformtaions py-2">
                                                        <div className="d-flex  align-items-baseline">
                                                            <i className="fas fa-clock mr-2"></i>
                                                            <p className="RowInformtaionsTitle">Horaires d’ouverture de la pharmacie :</p>
                                                        </div>
                                                        <div className="about_horaire ">
                                                            <table>
                                                                <tbody>

                                                                    {
                                                                        showHoraire ? <> {openingHoursOpen.length !== 0 && openingHoursOpen?.map((item, key) => {
                                                                            return <tr className={(this.state.today.charAt(0).toUpperCase() + this.state.today.slice(1)) === item?.dayOfWeek ? "about_horaire_bold" : ""} key={key}>
                                                                                <td className="day">{item?.dayOfWeek === 0 ? "Lundi" :
                                                                                    item?.dayOfWeek === 1 ? "Mardi" :
                                                                                        item?.dayOfWeek === 2 ? "Mercredi" :
                                                                                            item?.dayOfWeek === 3 ? "Jeudi" :
                                                                                                item?.dayOfWeek === 4 ? "Vendredi" :
                                                                                                    item?.dayOfWeek === 5 ? "Samedi" :
                                                                                                        item?.dayOfWeek === 6 ? "Dimanche" : item?.dayOfWeek}</td>
                                                                                {item?.dayOfWeek == "Dimanche" ? <td className="day-time">
                                                                                    <label>-</label>
                                                                                </td> : <td className="day-time">
                                                                                    <label>{(item?.startOne?.substring(0, 5) ?? "") + ' - ' + (item?.endOne?.substring(0, 5) ?? '')}</label>
                                                                                </td>}
                                                                                {(item?.dayOfWeek == "Samedi" || item?.dayOfWeek == "Dimanche") ? <td className="day-time">
                                                                                    <label>-</label>
                                                                                </td> : <td className="day-time">
                                                                                    <label>{(item?.startTwo?.substring(0, 5) ?? '') + ' - ' + (item?.endTwo?.substring(0, 5) ?? '')}</label>
                                                                                </td>}
                                                                            </tr>
                                                                        })} </> : <> {openingHoursOpen.length !== 0 && openingHoursOpen.slice(0, 2)?.map((item, key) => {
                                                                            return <tr className={(this.state.today.charAt(0).toUpperCase() + this.state.today.slice(1)) === item?.dayOfWeek ? "about_horaire_bold" : ""} key={key}>
                                                                                <td className="day">{item?.dayOfWeek === 0 ? "Lundi" :
                                                                                    item?.dayOfWeek === 1 ? "Mardi" :
                                                                                        item?.dayOfWeek === 2 ? "Mercredi" :
                                                                                            item?.dayOfWeek === 3 ? "Jeudi" :
                                                                                                item?.dayOfWeek === 4 ? "Vendredi" :
                                                                                                    item?.dayOfWeek === 5 ? "Samedi" :
                                                                                                        item?.dayOfWeek === 6 ? "Dimanche" : item?.dayOfWeek}</td>
                                                                                <td className="day-time">
                                                                                    <label>{(item?.startOne?.substring(0, 5) ?? "") + ' - ' + (item?.endOne?.substring(0, 5) ?? '')}</label>
                                                                                </td>
                                                                                {item?.dayOfWeek == "Samedi" ? '' : <td className="day-time">
                                                                                    <label>{(item?.startTwo?.substring(0, 5) ?? '') + ' - ' + (item?.endTwo?.substring(0, 5) ?? '')}</label>
                                                                                </td>}
                                                                            </tr>
                                                                        })} </>
                                                                    }

                                                                </tbody>
                                                            </table>
                                                            <div className="show_more">
                                                                <a className="lien_add" onClick={() => this.setState({ showHoraire: !showHoraire })}>{showHoraire ? "Masquer les horaires de la semaine" : "Afficher les horaires de la semaine"}</a>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="RowInformtaions py-2">
                                                        <div className="d-flex  align-items-baseline">
                                                            <i className="fas fa-credit-card mr-2"></i>
                                                            <p className="RowInformtaionsTitle">Moyens de paiement acceptés : {pharmacieData?.availablePaymentMethods.length === 0 && " " + " -"}</p>
                                                        </div>
                                                        {
                                                            availablePaymentMethodsPrepared.length !== 0 ?
                                                                <ul className="modes_paiement d-flex row">
                                                                    {availablePaymentMethodsPrepared.map((item, key) => {
                                                                        return item?.name === "cash" ?
                                                                            <li className="col-3 mb-1"><img src={Especes} style={{ width: "25px", height: "auto" }} />
                                                                                <p>Espèces</p></li> : item?.name === "check" ?
                                                                                <li className="col-3 mb-1"><img src={Cheque} style={{ width: "25px", height: "auto" }} />
                                                                                    <p>Chèque</p></li> : item?.name === "debit_card" ?
                                                                                    <li className="col-3 mb-1"><img src={CarteBancaire} style={{ width: "25px", height: "auto" }} />
                                                                                        <p>Carte Bancaire</p></li> : item?.name === "bill_of_exchange" ?
                                                                                        <li className="col-3 mb-1"><img src={Bill_of_Exchange} style={{ width: "25px", height: "auto" }} />
                                                                                            <p>Lettre de change</p></li> : item?.name === "credit" ?
                                                                                            <li className="col-3 mb-1"><img src={Credit} style={{ width: "25px", height: "auto" }} />
                                                                                                <p>Credit</p></li> : item?.name === "wire_transfer" ?
                                                                                                <li className="col-3 mb-1"><img src={Wire_Transfer} style={{ width: "25px", height: "auto" }} />
                                                                                                    <p>Virement bancaire</p></li> : item?.name === "other" ?
                                                                                                    <li className="col-3 mb-1"><img src={Other} style={{ width: "25px", height: "auto" }} />
                                                                                                        <p>Autre</p></li> :
                                                                                                    <li className="col-3 mb-1"></li>
                                                                    })}
                                                                </ul>
                                                                : ""
                                                        }

                                                    </div>

                                                    <div className="RowInformtaions  py-2">
                                                        <div className="d-flex  align-items-baseline">
                                                            <i className="fas fa-sign-in-alt mr-2"></i>
                                                            <p className="RowInformtaionsTitle">Dernière modification le : <span>{moment(pharmacieData && pharmacieData.updatedAt).format('DD/MM/YYYY')}</span></p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <PhoneNumberModal PhoneNumber={pharmacieData && pharmacieData.phone} />
                                        </>
                                        : ""}
                                    {active === 2 ?
                                        <PhotoGalerieRempliComponent photoDePharmacy={photoDePharmacy} />
                                        : ""}
                                </div>

                                <div className="col-4 px-0">
                                    {
                                        !linked && <div className="mb-2">
                                            <>
                                                <div className="SideBarButton ">
                                                    <button type="button" className="btn SideBarBtn d-flex justify-content-between align-items-center" onClick={() => window.open(LINK_PHARMA_PRO)}>
                                                        <p>C'est votre pharmacie ?<br />Devenez proprietaire de cette page</p>
                                                        <img src={ButtonImage} className="ButtonImage" />
                                                    </button>
                                                </div>
                                                <PrprietaireModal pharmacistId={pharmacieData?.pharmacist?.id} pharmacyId={pharmacieData?.id} />
                                            </>
                                        </div>
                                    }

                                    {!isRecommended && <>
                                        <div className="mb-2">
                                            {
                                                recommendationDesplay === 0 &&
                                                <div className="Recommendation px-2 py-3">
                                                    <h2>Vous recommandez cette pharmacie ?</h2>
                                                    <div className="buttons d-flex justify-content-center">
                                                        <a className="btn btn-primary RecommendationYesBtn d-flex align-items-baseline mr-2" data-toggle="modal" data-target={authonticatedUser ? "" : "#LoginModal"} onClick={() => authonticatedUser && this.setState({ recommendationDesplay: 1 })}>
                                                            Oui
                                                            < i className="fas fa-thumbs-up ml-1" ></i >
                                                        </a>
                                                        <a className="btn btn-primary RecommendationNoBtn d-flex align-items-baseline" data-toggle="modal" data-target={authonticatedUser ? "" : "#LoginModal"} onClick={authonticatedUser ? () => this.setState({ recommendationDesplay: 1 }) : ''}>
                                                            Non
                                                            <i className="fas fa-thumbs-down ml-1"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                recommendationDesplay === 2 &&
                                                <div className="RecomendationThanks Recommendation  text-center p-3">
                                                    <h2>Merci</h2>
                                                    <img src={Thanks} alt="monpharmacien.ma RecomendationThanks" className="RecomendationThanksImage " />
                                                </div>}

                                            {
                                                recommendationDesplay === 1 &&
                                                <div className="RecomondationRating px-2 py-3">
                                                    {AddrecommendedPharmaciesErrors && <div className="container rating_error">
                                                        <p>Les notes sont obligatoirement remplis</p>
                                                    </div>}

                                                    <div className="container RecomondationRatingAnswer">
                                                        <p>Disponibilité des produits</p>
                                                        <form className="d-flex justify-content-between "  >
                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input" value="1" checked={this.state.productAvailabilityScore === "1"} onChange={this.handleOptionChange} />
                                                                <label class="custom-control-label" for="customRadio1">1</label>
                                                            </div>
                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input" value="2" checked={this.state.productAvailabilityScore === "2"} onChange={this.handleOptionChange} />
                                                                <label class="custom-control-label" for="customRadio2">2</label>
                                                            </div>
                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="customRadio3" name="customRadio" className="custom-control-input" value="3" checked={this.state.productAvailabilityScore === "3"} onChange={this.handleOptionChange} />
                                                                <label class="custom-control-label" for="customRadio3">3</label>
                                                            </div>
                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="customRadio4" name="customRadio" className="custom-control-input" value="4" checked={this.state.productAvailabilityScore === "4"} onChange={this.handleOptionChange} />
                                                                <label class="custom-control-label" for="customRadio4">4</label>
                                                            </div>
                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="customRadio5" name="customRadio" className="custom-control-input" value="5" checked={this.state.productAvailabilityScore === "5"} onChange={this.handleOptionChange} />
                                                                <label class="custom-control-label" for="customRadio5">5</label>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="container RecomondationRatingAnswer">
                                                        <p>Courtoisie du personnel</p>
                                                        <form className="d-flex justify-content-between ">
                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="staffCourtesyScore1" name="staffCourtesyScore" className="custom-control-input" value="1" checked={this.state.staffCourtesyScore === "1"} onChange={this.staffHandleOptionChange} />
                                                                <label class="custom-control-label" for="staffCourtesyScore1">1</label>
                                                            </div>
                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="staffCourtesyScore2" name="staffCourtesyScore" className="custom-control-input" value="2" checked={this.state.staffCourtesyScore === "2"} onChange={this.staffHandleOptionChange} />
                                                                <label class="custom-control-label" for="staffCourtesyScore2">2</label>
                                                            </div>
                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="staffCourtesyScore3" name="staffCourtesyScore" className="custom-control-input" value="3" checked={this.state.staffCourtesyScore === "3"} onChange={this.staffHandleOptionChange} />
                                                                <label class="custom-control-label" for="staffCourtesyScore3">3</label>
                                                            </div>
                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="staffCourtesyScore4" name="staffCourtesyScore" className="custom-control-input" value="4" checked={this.state.staffCourtesyScore === "4"} onChange={this.staffHandleOptionChange} />
                                                                <label class="custom-control-label" for="staffCourtesyScore4">4</label>
                                                            </div>
                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="staffCourtesyScore5" name="staffCourtesyScore" className="custom-control-input" value="5" checked={this.state.staffCourtesyScore === "5"} onChange={this.staffHandleOptionChange} />
                                                                <label class="custom-control-label" for="staffCourtesyScore5">5</label>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="container RecomondationRatingAnswer">
                                                        <p>Aménagement de la pharmacie</p>
                                                        <form className="d-flex justify-content-between">

                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="pharmacyLayoutScore1" name="pharmacyLayoutScore" className="custom-control-input" value="1" checked={this.state.pharmacyLayoutScore === "1"} onChange={this.pharmacyHandleOptionChange} />
                                                                <label class="custom-control-label" for="pharmacyLayoutScore1">1</label>
                                                            </div>
                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="pharmacyLayoutScore2" name="pharmacyLayoutScore" className="custom-control-input" value="2" checked={this.state.pharmacyLayoutScore === "2"} onChange={this.pharmacyHandleOptionChange} />
                                                                <label class="custom-control-label" for="pharmacyLayoutScore2">2</label>
                                                            </div>
                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="pharmacyLayoutScore3" name="pharmacyLayoutScore" className="custom-control-input" value="3" checked={this.state.pharmacyLayoutScore === "3"} onChange={this.pharmacyHandleOptionChange} />
                                                                <label class="custom-control-label" for="pharmacyLayoutScore3">3</label>
                                                            </div>
                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="pharmacyLayoutScore4" name="pharmacyLayoutScore" className="custom-control-input" value="4" checked={this.state.pharmacyLayoutScore === "4"} onChange={this.pharmacyHandleOptionChange} />
                                                                <label class="custom-control-label" for="pharmacyLayoutScore4">4</label>
                                                            </div>
                                                            <div class="custom-control custom-radio RecomondationRatingAnswer">
                                                                <input type="radio" id="pharmacyLayoutScore5" name="pharmacyLayoutScore" className="custom-control-input" value="5" checked={this.state.pharmacyLayoutScore === "5"} onChange={this.pharmacyHandleOptionChange} />
                                                                <label class="custom-control-label" for="pharmacyLayoutScore5">5</label>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="RecommendationRatingTextErea mt-3">
                                                        <p>Commentaire</p>
                                                        <textarea className="form-control ContactInput RecomondationRatingTextErea" placeholder="Message" rows="5" />
                                                        <button className="btn btn-primary RecomondationRatingButton mt-3" onClick={() => this.AddrecommendedPharmacies(pharmacieData?.slug)}>Envoyer</button>
                                                    </div>
                                                </div>}
                                        </div>
                                    </>}
                                    <div className="mb-2">
                                        <>
                                            <div className="Localisation">
                                                <div className="card localisationCard">
                                                    <div className="card-header LocalisationCardHeader">
                                                        <i className="fas fa-map-marker-alt mr-2"></i> Localisation</div>
                                                    <div className="card-body localisationCardBody">
                                                        {
                                                            pharmacieData?.gpsLocation &&
                                                            <>
                                                                <div className="MapComponent">
                                                                    <GoogleMapReact
                                                                        style={{ height: "200px", position: "relative", zIndex: "10" }}
                                                                        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
                                                                        center={{
                                                                            lat: position[0],
                                                                            lng: position[1]
                                                                        }}
                                                                        zoom={13}
                                                                        onClick={() => {
                                                                            const action = {
                                                                                action: "Itinerary"
                                                                            }
                                                                            this.ActionOfClientInPharmacie(pharmacieData?.slug, action)
                                                                        }}
                                                                    >
                                                                        <AnyReactComponent
                                                                            lat={position[0]}
                                                                            lng={position[1]}
                                                                            text={pharmacieData && pharmacieData.name}
                                                                        />
                                                                    </GoogleMapReact>
                                                                </div>
                                                            </>
                                                        }
                                                        <p><strong>Adresse : </strong>
                                                            {
                                                                pharmacieData ? pharmacieData.address : <a href="" className="lien_add suggest_address" data-toggle="modal" data-target={authonticatedUser ? "#AdresseModal" : "#LoginModal"}>Renseignez l'adresse</a>
                                                            }</p>
                                                        <p><strong>Ville &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:  </strong>
                                                            {pharmacieData && pharmacieData.city ? pharmacieData.city.name : ""}</p>
                                                        {pharmacieData?.gpsLocation ? "" :
                                                            <>
                                                                <p><strong>Secteur : </strong> {pharmacieData && pharmacieData.sector ? pharmacieData.sector.name : "-"}</p>
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>
                                            <SecteurModal />
                                            <AdresseModal />
                                            <LocalisationModal />
                                        </>
                                    </div>
                                    <div className="mb-2">
                                        <Share />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <HorairesModel />
                <ServicesModel />
                <LanguegeModel />
                <SuggestTelephoneModel />
                <MoyensDePaiementModel />
                <SuggestPictureCoverture />
                <SuggestPharmacistPictureModal />
                <PharmacienModal />
                <DeplomeModal />
                <SuggestionModel />
                <VilleModel />
            <PharmacieModel /> */}
                <LoginModal />
            </div >
        );
    }
}

export default withRouter(PharmacieInfoComponent);