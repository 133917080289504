import axios from "axios";
import {api_url} from "../config";

const authAxios = axios.create({
    baseURL: api_url + "cities"
});

const authSecteurAxios = axios.create({
    baseURL: api_url + "sectors"
});

const authContactAxios = axios.create({
    baseURL: api_url + "app/contact-us"
});

class citiesService {
    constructor() {
        authAxios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
    }

    getAllCities() {
        let params = {
            limit: 1000
        }
        return authAxios.get('', { params });
    }

    getSectorsCities(cityId) {
        let params = {
            city_id: cityId
        }
        return authSecteurAxios.get('', { params });
    }

    contactUs(data) {
        return authContactAxios.post('', data);
    }
}

export default new citiesService();