import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../../helpers/Footer/Footer';
import Header from '../../helpers/Header/Header';
import Loader from '../../helpers/Loader';
import patientsService from '../../services/patients.service';
import SearchSelect from '../../helpers/AsyncSelect';
import "./CompleteRegistration.css";
import { LOGIN_PATIENT } from '../../config';

class CompleteRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVevified: false,
            authonticatedUser: null,
            emailSnt: false,
            location: null,
            country: "ma",
            selectedCity: null,
            selectedSector: null,
            sectorId: null,
            cityId: null,
            zipcode: null,
            loader: true,
            conditionsCheck: false,
            conditionsCheckError: false
        };
        this.handleChange = this.handleChange.bind(this)
    }


    componentDidMount() {
        document.title = "MonPharmacien - 1er annuaire interactif de pharmacies au Maroc";
        this.getPatientLocalisation()
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ authonticatedUser: response.data, loader: false })
        } catch (error) {
            console.log(error);
        }
    }

    handleChange = (e) => {
        this.setState({ country: e.target.value, zipcode: null });
    };

    handleZipcodeChange = (e) => {
        this.setState({ zipcode: e.target.value, selectedCity: [] });
    };

    handleValidation = async () => {
        const { conditionsCheck } = this.state;
        let conditionsCheckError = null
        let formIsValid = true;

        if (conditionsCheck === false) {
            formIsValid = false;
            conditionsCheckError = true
        }

        this.setState({ conditionsCheckError });
        return formIsValid;
    };

    updatePatient = async () => {
        this.setState({ conditionsCheckError: false }, async () => {
            let valid = await this.handleValidation();
            console.log('valid', valid);
            if (valid) {
                try {
                    let fieldsCopy = this.state.fields
                    fieldsCopy = {
                        city: this.state.cityId,
                        sector: this.state.sectorId,
                        country: this.state.country,
                        zipcode: this.state.zipcode
                    }
                    await patientsService.updatePatient(fieldsCopy);
                    this.props.history.push('/')
                } catch (error) {
                    console.log(error);
                }
            }
        });
    }

    getPatientLocalisation = async () => {
        try {
            let response = await patientsService.getPatientLocalisation()
            this.setState({ country: response.data?.countryCode ?? "ma", loader: false })
        } catch (error) {
            if (error.response?.status === 401) {
                window.location.href = LOGIN_PATIENT
            }
            console.log(error);
        }
    }

    handleSkip = async () => {
        try {
            const data = {
                country: this.state.country
            }
            await patientsService.updatePatient(data);
            this.props.history.push('/')
        } catch (error) {
            this.props.history.push('/')
        }
    }

    creatImage = () => {
        let canvasTxt = document.getElementById("canvasComponent").getContext("2d");
        canvasTxt.canvas.width = canvasTxt.measureText('support@sobrus.com').width + 30;
        canvasTxt.canvas.height = 22;
        canvasTxt.canvas.fillStyle = "#ffffff";
        canvasTxt.fillStyle = "#ffffff";
        canvasTxt.canvas.font = "12px openSans";
        canvasTxt.font = "12px openSans";
        canvasTxt.fillText("support@sobrus.com", 0, 14);
        this.setState({ img: canvasTxt.canvas.toDataURL() });
    }

    render() {
        const { loader, country, emailSnt, cityId, zipcode } = this.state
        return (
            <div className="ContactPageComponent">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine" />
                </Helmet>
                {
                    emailSnt && <div className="sign_up_notification">
                        <i className="far fa-check-circle"></i>
                        <p>Votre message a été bien envoyé</p>
                    </div>
                }
                {loader && <Loader />}
                <Header />
                <div className="ContactForm">
                    <div className="ContactFormHeader">
                        <h2 className="ContactFormTitle">Complétez votre inscription</h2>
                        <p className="ContactFormparagraphe">Nous vous invitons à compléter vos informations.</p>
                    </div>
                    <div className="container ContactFormCorp">
                        <div className="col-10 offset-1">
                            <div className="row">
                                <div className="col-8 offset-2">
                                    <div className="d-flex justify-content-between align-items-center p-3 ContactPageComponent_header mb-4">
                                        <div>
                                            <p className="CompleteRegistration_conplite_porcentege"> Vous y êtes presque<br />Il ne vous reste qu’une dernière étape </p>
                                        </div>
                                        <div class="progress blue"> <span class="progress-left"> <span class="progress-bar"></span> </span> <span class="progress-right"> <span class="progress-bar"></span> </span>
                                            <div class="progress-value">85%</div>
                                        </div>
                                    </div>
                                    <div className="form-group w-100">
                                        <div className="form-group SecteurModalInputs w-100">
                                            <label className=" EditPresonnalInformationLabels">Pays :</label>
                                            <select className="form-control EditPresonnalInformationInputs" onChange={this.handleChange} value={country} >
                                                <option value="dj" >Djibouti</option>
                                                <option value="ma" >Maroc</option>
                                                <option value="sn" >Sénégal</option>
                                            </select>
                                        </div>
                                        <div className="form-group SecteurModalInputs w-100">
                                            <label for="sel1">Ville :</label>
                                            <SearchSelect
                                                className="form-control SearchInputs"
                                                fetchedUrl='/cities'
                                                HTTPMethod="getcities"
                                                receivedObject="cities"
                                                optionLabelProperty="name"
                                                optionValueProperty="id"
                                                country={country}
                                                value={this.state.selectedCity}
                                                placeholder="Entrez la ville"
                                                onChange={(e) => {
                                                    if (e) {
                                                        this.setState({ selectedCity: [], cities: [] }, () => {
                                                            let city = {
                                                                id: e.value
                                                            }
                                                            this.state.cityId = city;
                                                            this.setState({
                                                                cityId: city,
                                                                selectedCity: [...this.state.selectedCity, e],
                                                                selectedSector: null
                                                            })
                                                        })
                                                    }
                                                }}
                                                styles={{
                                                    loadingIndicator: (provided, state) => ({
                                                        ...provided,
                                                        display: "none",
                                                    }),
                                                    indicatorSeparator: (provided, state) => ({
                                                        ...provided,
                                                        display: "none",
                                                    }),
                                                    container: (provided, state) => ({
                                                        ...provided,
                                                    }),
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderWidth: "2px",
                                                        borderRadius: "none",
                                                        boxShadow: "none",
                                                        height: "42px",
                                                        borderRadius: "3px",
                                                        borderColor: "#e5eef7",
                                                        ":hover": {
                                                            borderColor: "none",
                                                            borderWidth: "2px",
                                                        },
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontFamily: "OpenSans",
                                                        fontWeight: "600",
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        backgroundColor: state.isSelected && "#ffff",
                                                        fontFamily: "OpenSans",
                                                        borderBottom: " 1px solid #e5eef7",
                                                        padding: "5px",
                                                        color: "#52747d",
                                                        fontSize: "13px",
                                                        textAlign: 'start',
                                                        ":hover": {
                                                            color: "#fff",
                                                            backgroundColor: "#1bc48a",
                                                        },
                                                    }),
                                                    clearIndicator: (provided, state) => ({
                                                        ...provided,
                                                        paddingRight: 0,
                                                    }),
                                                    dropdownIndicator: (provided, state) => ({
                                                        ...provided,
                                                        paddingLeft: 0,
                                                    })
                                                }}
                                            />
                                        </div>
                                        {country === "ma" && <div className="form-group SecteurModalInputs w-100">
                                            <label for="sel1">Secteur :</label>
                                            <SearchSelect
                                                className="form-control SearchInputs"
                                                fetchedUrl='/sectors'
                                                HTTPMethod="getsectors"
                                                receivedObject="sectors"
                                                optionLabelProperty="name"
                                                optionValueProperty="id"
                                                country={country}
                                                cityId={cityId?.id}
                                                value={this.state.selectedSector}
                                                placeholder="Entrez le secteur"
                                                onChange={(e) => {
                                                    if (e) {
                                                        this.setState({ selectedSector: [], cities: [] }, () => {
                                                            let sector = {
                                                                id: e.value
                                                            }
                                                            this.state.sectorId = sector;
                                                            this.setState({
                                                                sectorId: sector,
                                                                selectedSector: [...this.state.selectedSector, e]
                                                            })
                                                        })
                                                    }
                                                }}
                                                styles={{
                                                    loadingIndicator: (provided, state) => ({
                                                        ...provided,
                                                        display: "none",
                                                    }),
                                                    indicatorSeparator: (provided, state) => ({
                                                        ...provided,
                                                        display: "none",
                                                    }),
                                                    container: (provided, state) => ({
                                                        ...provided,
                                                    }),
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderWidth: "2px",
                                                        borderRadius: "none",
                                                        boxShadow: "none",
                                                        height: "42px",
                                                        borderRadius: "3px",
                                                        borderColor: "#e5eef7",
                                                        ":hover": {
                                                            borderColor: "none",
                                                            borderWidth: "2px",
                                                        },
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontFamily: "OpenSans",
                                                        fontWeight: "600",
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        backgroundColor: state.isSelected && "#ffff",
                                                        fontFamily: "OpenSans",
                                                        borderBottom: " 1px solid #e5eef7",
                                                        padding: "5px",
                                                        color: "#52747d",
                                                        fontSize: "13px",
                                                        textAlign: 'start',
                                                        ":hover": {
                                                            color: "#fff",
                                                            backgroundColor: "#1bc48a",
                                                        },
                                                    }),
                                                    clearIndicator: (provided, state) => ({
                                                        ...provided,
                                                        paddingRight: 0,
                                                    }),
                                                    dropdownIndicator: (provided, state) => ({
                                                        ...provided,
                                                        paddingLeft: 0,
                                                    })
                                                }}
                                            />
                                        </div>}
                                        {country !== "ma" && <div className="form-group SecteurModalInputs w-100">
                                            <label for="sel1">Code postal :</label>
                                            <input type="text" className="form-control ContactFormInputs" id="zipcode" aria-describedby="zipcode" placeholder="Entrez votre code postal" onChange={this.handleZipcodeChange} value={zipcode} />
                                        </div>}
                                        <div className="form-group">
                                            <p className="form_group_paraphe">Par le biais de ce formulaire, MonPharmacien collecte vos données personnelles en vue de répondre à votre demande suite à l’envoi du formulaire de contact.
Ce traitement a fait l’objet d’une déclaration de la CNDP sous le numéro <strong>D-GC-252/2018.</strong></p>
                                            <p className="form_group_paraphe">Vous pouvez vous adresser à Sobrus par e-mail à l’adresse suivante : <a><canvas id="canvasComponent" style={{ display: "none" }} /><img src={this.state.img} /></a> pour exercer vos droits d’accès, de rectification et d’opposition conformément aux dispositions de la loi 09-08.</p>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-check custom-checkbox">
                                                <input type="checkbox" className="form-check-input custom-control-input checkbox" id="ContactPageComponent" onChange={() => this.setState({ conditionsCheck: !this.state.conditionsCheck })} checked={this.state.conditionsCheck} />
                                                <label className="form-check-label custom-control-label" htmlFor="ContactPageComponent" >
                                                    <p className="form_group_paraphe_new">J'ai lu et approuvé <a onClick={() => {
                                                        window.scrollTo(0, 0)
                                                        this.props.history.push('/privacy_policy')
                                                    }}>les conditions générales d'utilisation</a> de MonPharmacien </p>
                                                </label>
                                            </div>
                                            {this.state.conditionsCheckError && <p className="form_group_paraphe_error">*Champ obligatoire </p>}
                                        </div>
                                    </div>
                                    <div className="ContactButton text-center">
                                        <button className="btn ContactFormButton" onClick={() => this.updatePatient()}>Enregistrer</button>
                                        {/* <p className="CompleteRegistration_skip" onClick={() => this.handleSkip()} >Compléter plus tard</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default CompleteRegistration;
