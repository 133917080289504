import React, { Component } from 'react';
import ModalLayout from '../../ModalLayout/ModalLayout';
import './AdresseModal.css';

class AdresseModal extends Component {
    render() {
        return (
            <ModalLayout title="RENSEIGNEZ L'ADRESSE" lg={false} sendBtn={{ text: "Envoyer" }} cancelBtn={{ text: "Annuler" }} bodyParagraphe="Merci de participer à rendre l'information accessible aux patients :" id="AdresseModal">
                <div className="AdresseModal">
                    <div className="form-group AdresseModalInputs my-3">
                        <label for="comment">Adresse :</label>
                        <textarea type="text" name="morning_from[]" placeholder="Entrez l'adresse" className="p-2 form-control" rows="5" id="comment"></textarea>
                    </div>
                </div>
            </ModalLayout>
        );
    }
}

export default AdresseModal;