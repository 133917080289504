import AsyncSelect from "react-select/async";
import React, { Component } from "react";
import axios from "axios";
import { api_url } from "../config";


const authAxios = axios.create({
    baseURL: api_url
});

const convertDataForSelectBox = (data, valueProperty, labelProperty) => {
    let convertedData = [];

    data.map((object) => {
        return convertedData.push({ value: valueProperty ? object[valueProperty] : "", label: labelProperty ? object[labelProperty] : "" });
    });
    return convertedData;
}

const convertAllDataForSelectBox = (data, valueProperty, labelProperty) => {
    let convertedData = [];

    data.map((object) => {
        {
            return convertedData.push({
                value: valueProperty ? object : "",
                label: labelProperty ? object.city[labelProperty] + (object.sector ? " (" + object.sector[labelProperty] + ")" : " (TOUS LES SECTEURS)") : ""
            });
        }
    });
    
    return convertedData;
}

class SearchSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            fetchedUrl: this.props.fetchedUrl,
            allData: []
        };
        this.noOptionsMessage = this.noOptionsMessage.bind(this)
        authAxios.interceptors.response.use(
            function (response) {
                return response;
            }
        );
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fetchedUrl !== this.props.fetchedUrl) {
            this.setState({ fetchedUrl: this.props.fetchedUrl }, () => this.getData())
        } if (prevProps.cityId !== this.props.cityId) {
            this.setState({ data: [] }, () => this.getData())
        }
        if (prevProps.country !== this.props.country) {
            this.setState({ data: [] }, () => this.getData())
        }
    }

    getData = async (inputValue = null, callback = () => { }) => {
        try {
            const { HTTPMethod, receivedObject, optionLabelProperty, optionValueProperty, onInputChange, cityId, country } = this.props;
            const { fetchedUrl, allData } = this.state;
            if (HTTPMethod.toLocaleLowerCase() === "getcities") {
                let content = "";
                if (inputValue) {
                    content = inputValue
                }
                let convertedArray = [];
                const response = await authAxios.get(`${fetchedUrl}?country=${country}&name=${content}`, { withCredentials: true });
                this.setState({ data: receivedObject ? response.data[receivedObject] : response.data }, () => {
                    convertedArray = convertDataForSelectBox(receivedObject ? response.data[receivedObject] : response.data, optionValueProperty, optionLabelProperty);
                });
                callback(convertedArray);
            }
            if (HTTPMethod.toLocaleLowerCase() === "getsectors") {
                let content = "";
                if (inputValue) {
                    content = inputValue
                }
                let convertedArray = [];
                let params = {
                    city_id: cityId
                }
                const response = await authAxios.get(`${fetchedUrl}?country=${country}&name=${content}`, { params });
                this.setState({ data: receivedObject ? response.data[receivedObject] : response.data }, () => {
                    convertedArray = convertDataForSelectBox(receivedObject ? response.data[receivedObject] : response.data, optionValueProperty, optionLabelProperty);
                });
                callback(convertedArray);
            }
            if (HTTPMethod.toLocaleLowerCase() === "getall") {
                let content = "";
                if (inputValue) {
                    content = inputValue
                }
                let convertedArray = [];

                const response = await authAxios.get(`${fetchedUrl}?country=${country}&name=${content}`);
                this.setState({ allData: response.data }, () => {
                    convertedArray = convertAllDataForSelectBox(this.state.allData, optionValueProperty, optionLabelProperty);
                });
                callback(convertedArray);
            }
        } catch (error) {
            this.setState({ is_loaded: false });
            console.log(error);
        }
    };

    noOptionsMessage() {
        return this.props.noOptoins;
    }

    render() {
        const { value, placeholder, onChange, optionLabelProperty, optionValueProperty, styles, country } = this.props;
        return (
            <AsyncSelect
                value={value}
                noOptionsMessage={this.noOptionsMessage}
                width={"100%"}
                escapeClearsValue={true}
                isClearable={false}
                country={country}
                loadOptions={this.getData}
                placeholder={placeholder}
                onChange={onChange}
                defaultOptions={convertDataForSelectBox(this.state.data, optionValueProperty, optionLabelProperty)}
                styles={styles}
            />
        );
    }
}

export default SearchSelect;
