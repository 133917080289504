import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../../helpers/Footer/Footer';
import Header from '../../helpers/Header/Header';
import './PrivacyPolicy.css';

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="ContactPageComponent">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine" />
                </Helmet>
                <Header />
                <div className="ContactForm">
                    <div className="ContactFormHeader">
                        <h2 className="ContactFormTitle">Politique de confidentialité</h2>
                    </div>
                    <div className="container">
                        <div className="PrivacyPolicy row">
                            <div className="PrivacyPolicy_conatianer">
                                <p className="PrivacyPolicy_pharagraphes">Sobrus attache une grande importance à la protection des données à caractère personnel et de la vie privée de ses utilisateurs. A cette fin, Sobrus agit en tant
                                        que responsable du traitement et s’engage à procéder aux  traitements de ces données à caractère personnel conformément aux lois et réglementations applicables;</p>
                                <p className="PrivacyPolicy_pharagraphes">La présente Politique de Confidentialité expose la manière dont Sobrus collecte et traite des données vous concernant dans le cadre de votre utilisation de notre application.</p>
                            </div>
                            <div className="PrivacyPolicy_conatianer">
                                <p className="PrivacyPolicy_big_title">1. QU’EST-CE QU’UNE DONNEE A CARACTERE PERSONNEL ? </p>
                                <p className="PrivacyPolicy_pharagraphes">Les données à caractère personnel désignent toutes informations se rapportant à une  personne physique identifiée ou identifiable (appelée « personne concernée »).</p>
                                <p className="PrivacyPolicy_pharagraphes">Dans le cadre du site web « www.monpharmacien.ma » et application mobile « MonPharmacien », les personnes concernées sont les utilisateurs créant un compte  sur le site web et utilisant les services proposés.</p>
                                <p className="PrivacyPolicy_pharagraphes">Les données à caractère personnel concernées par la présente Politique de Confidentialité  sont donc toutes données se rapportant à ces internautes et utilisateurs, dans la mesure où ils  sont identifiés ou identifiables, par exemple au moyen de l’adresse IP de leur terminal ou de leur identifiant de compte utilisateur. </p>
                            </div>
                            <div className="PrivacyPolicy_conatianer">
                                <p className="PrivacyPolicy_big_title mb-5">2. QUELLES DONNEES A CARACTERE PERSONNEL SONT COLLECTEES, POUR QUELLES FINALITES,  ET SUR QUELLE BASE LEGALE ? </p>
                                <p className="PrivacyPolicy_pharagraphes mb-1">Sobrus collecte des données à caractère personnel concernant ses  utilisateurs dans les cas suivants, pour les finalités suivantes :</p>
                            </div>
                            <div className="PrivacyPolicy_conatianer w-100 mb-5">
                                <table class="table PrivacyPolicy_table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Pourquoi les données à personnelles sont utilisées ?  Finalités de traitement</th>
                                            <th scope="col">Quelles sont les données à personnelles utilisées ?</th>
                                            <th scope="col">Sur quelle base légale les données sont-elles traitées ?</th>
                                            <th scope="col">Combien de temps les données  sont-elles conservées ?</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">Gestion du compte utilisateur  </p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- création et gestion du  compte utilisateur ;  </p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Gestion des notifications  relatives au traitement des  ordonnances </p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- gestion par l’utilisateur des  Pharmacies : recherche, favoris… </p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- fourniture d’informations  relatives à la gestion du compte (informations relatives au compte, nouvelles fonctionnalités,évolutions) </p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- fourniture d’un support (E mail :<a>support{'{AT}'}sobrus.com</a>) (réponse aux demandes des  utilisateurs) </p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Gestion des données de  géolocalisation pour permettre au patient de trouver la pharmacie la plus proche de là où il se trouve</p>
                                            </td>
                                            <td>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">Gestion du compte utilisateur  </p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Nom, Prénom, </p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Mot de passe (crypté),</p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Adresse email,</p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Numéro de  téléphone,</p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Ville, code postal et secteur,</p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Données de compte : date et heure de  création et de suppression du compte,</p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Données de localisation.</p>
                                            </td>
                                            <td>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">Nécessaire à l’exécution  d’un contrat auquel la personne concernée est  partie ou à l’exécution de  mesures précontractuelles  prises à la demande de  celle-ci (acceptation des Conditions Générales d’Utilisation) </p>
                                            </td>
                                            <td>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">5 ans à compter de la dernière  demande auprès d’une pharmacie en cas d’inactivité du  compte utilisateur ou jusqu’à  demande de suppression de la  part de l’utilisateur. </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">Gestion et partage par les  utilisateurs de leurs documents</p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Permettre à l’utilisateur de  télécharger son ordonnance  sur le site web</p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Permettre à l’utilisateur de  transmettre son ordonnance  à la pharmacie choisie </p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Accès à un historique des  ordonnances et du détails des ordonnances et historique du traitement de  ses ordonnances</p>
                                            </td>
                                            <td>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Nom, prénom ; </p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Téléphone ;</p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Ville, code postal</p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Historique des changements de statut (ex: en cours de traitement) ; </p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">L’ensemble des  informations et  documents stockés et échangés via le site seront encryptés et inaccessibles par Sobrus. </p>
                                            </td>
                                            <td>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">Nécessaire à l’exécution  d’un contrat auquel la  personne concernée est  partie ou à l’exécution de  mesures précontractuelles  prises à la demande de celle-ci (acceptation des  Conditions Générales d’Utilisation)</p>
                                            </td>
                                            <td>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">Jusqu’à suppression par l’utilisateur du document ou de son compte Sobrus. Afin de se prémunir contre  toute suppression non désirée  et litige lié, Sobrus est  susceptible de conserver le document supprimé sept (7) jours à compter de la demande de suppression. Passé ce délai, le document sera  définitivement supprimé.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">Utilisation des applications et des appareils (utilisation de  l’application monpharmacien.sobrus.com)</p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">Prévention et lutte contre la  fraude informatique  (spamming, hacking…)</p>
                                            </td>
                                            <td>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Données de connexion et d’utilisation du site web (date et  heure d’utilisation du site web, ID de  session),  </p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Matériel informatique utilisé</p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Adresse IP </p>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">- Cookies techniques</p>
                                            </td>
                                            <td>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">Intérêt légitime de Sobrus </p>
                                            </td>
                                            <td>
                                                <p className="PrivacyPolicy_pharagraphes mb-2">6 mois à compter du dernier contact avec une pharmacie. </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="PrivacyPolicy_conatianer">
                                <p className="PrivacyPolicy_big_title">3. AVEC QUI LES DONNEES SONT-ELLES PARTAGEES ? </p>
                                <p className="PrivacyPolicy_pharagraphes">Les données à caractère personnel collectées et traitées ainsi qu’exposé à l’article 2 ci-avant  sont utilisées par les personnes habilitées au sein de Sobrus et sous traitants habilités. </p>
                            </div>
                            <div className="PrivacyPolicy_conatianer">
                                <p className="PrivacyPolicy_big_title">4. POUR QUELLE DUREE LES DONNEES SONT-ELLES CONSERVEES ? </p>
                                <p className="PrivacyPolicy_pharagraphes">Les données à caractère personnel collectées et listées ainsi qu’exposé à l’article 2 ci-avant  sont conservées par Sobrus pour des durées n’excédant pas  l’accomplissement des finalités décrites à l’article 2. </p>
                            </div>
                            <div className="PrivacyPolicy_conatianer">
                                <p className="PrivacyPolicy_big_title">5. VOS DROITS </p>
                                <p className="PrivacyPolicy_pharagraphes">En application des lois et réglementations applicables en matière de protection des données à  caractère personnel, vous disposez des droits suivants concernant le traitement de vos  données à caractère personnel.  </p>
                                <p className="PrivacyPolicy_pharagraphes">Vous pouvez les exercer en nous contactant à l’adresse suivante : support{'{AT}'}sobrus.com. </p>
                                <p className="PrivacyPolicy_pharagraphes mb-4">Ces droits étant purement personnels, et ne pouvant par conséquent être exercés que par la  personne concernée elle-même, merci de joindre à votre demande, outre les motifs de celle-ci, une copie de votre pièce d’identité. Celle-ci ne sera pas conservée plus longtemps que le  temps nécessaire à la vérification de votre identité.  </p>

                                <div className="PrivacyPolicy_sub_conatianer">
                                    <p className="PrivacyPolicy_title">5.1 Droit d’accès </p>
                                    <p className="PrivacyPolicy_pharagraphes">L’utilisateur peut avoir accès à ses données à caractère personnel traitées par Sobrus. Dans  l’hypothèse où demande lui en serait faite, Sobrus fournira ainsi à l’utilisateur copie de toutes ses données à caractère personnel ainsi que toutes les informations légalement requises, à  savoir : </p>
                                    <p className="PrivacyPolicy_pharagraphes PrivacyPolicy_pharagraphes_with_dot">Les catégories des données personnelles collectées et traitées ; </p>
                                    <p className="PrivacyPolicy_pharagraphes PrivacyPolicy_pharagraphes_with_dot">Les finalités de leur traitement ; </p>
                                    <p className="PrivacyPolicy_pharagraphes PrivacyPolicy_pharagraphes_with_dot"> Les catégories des destinataires auxquels les données à caractère personnel ont été  communiquées ou sont susceptibles d’être communiquées ; </p>
                                    <p className="PrivacyPolicy_pharagraphes PrivacyPolicy_pharagraphes_with_dot">La durée de conservation des données à caractère personnel; et </p>
                                    <p className="PrivacyPolicy_pharagraphes PrivacyPolicy_pharagraphes_with_dot">Des informations concernant les droits de l’utilisateur à l’égard de ses données à  caractère personnel. </p>
                                </div>

                                <div className="PrivacyPolicy_sub_conatianer">
                                    <p className="PrivacyPolicy_title">5.2 Droit à la portabilité </p>
                                    <p className="PrivacyPolicy_pharagraphes">Pour ceux, parmi les traitements listés à l’article 2 ci-avant, ayant pour base légale le  consentement de l’utilisateur ou leur nécessité pour l’exécution d’un contrat, l’utilisateur  dispose également du droit à la portabilité de ses données à caractère personnel. </p>
                                    <p className="PrivacyPolicy_pharagraphes">Ce droit se distingue du droit d’accès aux données à caractère personnel dans la mesure où (i)  il ne concerne que les données fournies par l’utilisateur à Sobrus et (ii) il permet d’obtenir  ces données dans un format structuré et lisible par machine.</p>
                                    <p className="PrivacyPolicy_pharagraphes">Le droit à la portabilité des données à caractère personnel de l’utilisateur ouvre en sus la  possibilité qu’elles soient transmises à un autre responsable de traitement, au choix de  l’utilisateur, sous réserve que cela soit techniquement possible. </p>
                                </div>

                                <div className="PrivacyPolicy_sub_conatianer">
                                    <p className="PrivacyPolicy_title">5.3 Droit de rectification </p>
                                    <p className="PrivacyPolicy_pharagraphes">L’utilisateur peut demander à corriger ses données à caractère personnel si celles-ci se  révèlent inexactes, incomplètes ou obsolètes. </p>
                                </div>

                                <div className="PrivacyPolicy_sub_conatianer">
                                    <p className="PrivacyPolicy_title">5.4 Droit à l’effacement</p>
                                    <p className="PrivacyPolicy_pharagraphes">L’utilisateur peut demander à ce que ses données à caractère personnel soient supprimées  dans les cas suivants :  </p>
                                    <p className="PrivacyPolicy_pharagraphes PrivacyPolicy_pharagraphes_with_dot">si ses données à caractère personnel ne sont plus nécessaires au regard des finalités  pour lesquelles elles ont été collectées ou sont traitées ; </p>
                                    <p className="PrivacyPolicy_pharagraphes PrivacyPolicy_pharagraphes_with_dot">si l’utilisateur a retiré son consentement au traitement de ses données à caractère  personnel, sous réserve que son consentement préalable ait été la base légale de leur  collecte et leur traitement et qu’il n’existe pas d’autre base légale les justifiant ; </p>
                                    <p className="PrivacyPolicy_pharagraphes PrivacyPolicy_pharagraphes_with_dot">si l’utilisateur s’est opposé à la collecte ou au traitement de ses données à caractère  personnel conformément à l’article 5.5 ci-après ; </p>
                                    <p className="PrivacyPolicy_pharagraphes PrivacyPolicy_pharagraphes_with_dot">le traitement des données à caractère personnel de l’utilisateur est illégal ; • les données à caractère personnel de l’utilisateur doivent être supprimées pour que  Sobrus se conforme à une obligation légale mise à sa charge ; ou</p>
                                    <p className="PrivacyPolicy_pharagraphes PrivacyPolicy_pharagraphes_with_dot"> si l’utilisateur était mineur au moment de la collecte de ses données à caractère  personnel. Pour ce dernier cas de figure et sous réserve que l’utilisateur soit mineur  au moment de la demande, les titulaires de l’exercice de l’autorité parentale de  l’utilisateur peuvent également formuler une demande de suppression de ses données  à caractère personnel auprès de Sobrus. </p>
                                    <p className="PrivacyPolicy_pharagraphes ">Si les données concernées ont été communiquées à des tiers, Sobrus informera alors ces tiers  de la demande de l’utilisateur, dans la mesure du possible.</p>
                                </div>

                                <div className="PrivacyPolicy_sub_conatianer">
                                    <p className="PrivacyPolicy_title">5.5 Droit d’opposition </p>
                                    <p className="PrivacyPolicy_pharagraphes">Sous réserve d’une justification légitime tenant à la particularité de sa situation, l’utilisateur  peut s’opposer à tout traitement de ses données à caractère personnel lorsque ses données à  caractère personnel sont traitées sur le fondement de la nécessité de ce traitement pour  l’exécution d’une mission d’intérêt public ou de la poursuite de l’intérêt légitime de Sobrus  ou d’un tiers.</p>
                                    <p className="PrivacyPolicy_pharagraphes">L’utilisateur peut également toujours s’opposer, sans obligation de motif, au traitement de ses données à caractère personnel lorsqu’il intervient à des fins de prospection. </p>
                                </div>

                                <div className="PrivacyPolicy_sub_conatianer">
                                    <p className="PrivacyPolicy_title">5.6 Droit à la limitation du traitement </p>
                                    <p className="PrivacyPolicy_pharagraphes">L’utilisateur peut demander la limitation du traitement de ses données à caractère  personnel dans les cas suivants : </p>
                                    <p className="PrivacyPolicy_pharagraphes PrivacyPolicy_pharagraphes_with_dot">dans l’hypothèse où il contesterait l’exactitude de ses données à caractère personnel,  et ce pour la durée nécessaire à Sobrus pour la vérifier ; </p>
                                    <p className="PrivacyPolicy_pharagraphes PrivacyPolicy_pharagraphes_with_dot">si le traitement de ses données à caractère personnel est illicite et que l’utilisateur  souhaite le limiter plutôt que de demander la suppression de ces dernières ; </p>
                                    <p className="PrivacyPolicy_pharagraphes PrivacyPolicy_pharagraphes_with_dot">si l’utilisateur souhaite que Sobrus conserve ses données à caractère personnel  lorsque celles-ci sont nécessaires à la constatation, l’exercice ou la défense de ses  droits en justice ; ou </p>
                                    <p className="PrivacyPolicy_pharagraphes PrivacyPolicy_pharagraphes_with_dot">si l’utilisateur s’est opposé au traitement de ses données à caractère personnel,  pendant la période au cours de laquelle Sobrus vérifie si elle n’a pas d’autres motifs  légitimes prépondérants pour poursuivre leur traitement.</p>
                                    <p className="PrivacyPolicy_pharagraphes">Sobrus cessera alors de traiter les données concernées et les conservera pour la durée  appropriée. </p>
                                </div>

                                <div className="PrivacyPolicy_sub_conatianer">
                                    <p className="PrivacyPolicy_title">5.7 Droit de définir des directives relatives au sort des données après sa mort </p>
                                    <p className="PrivacyPolicy_pharagraphes">L’utilisateur peut indiquer à Sobrus des directives concernant les modalités de conservation,  d’effacement et de communication de ses données à caractère personnel à intervenir après  son décès. </p>
                                </div>

                                <div className="PrivacyPolicy_sub_conatianer">
                                    <p className="PrivacyPolicy_title">5.8 Droit au retrait du consentement </p>
                                    <p className="PrivacyPolicy_pharagraphes">Pour tous les traitements, parmi ceux listés à l’article 2 ci-avant, ayant pour fondement légal  le consentement de l’utilisateur, ce dernier dispose du droit de retirer ce consentement à tout moment, sans avoir à s’en justifier auprès de Sobrus. </p>
                                </div>

                                <div className="PrivacyPolicy_sub_conatianer">
                                    <p className="PrivacyPolicy_title">5.9 Droit d’introduire une réclamation auprès d’une autorité de contrôle</p>
                                    <p className="PrivacyPolicy_pharagraphes">L’utilisateur dispose enfin du droit d’introduire une réclamation relative aux traitements mis  en œuvre par Sobrus auprès de l’autorité de contrôle compétente. </p>
                                </div>
                            </div>

                            <div className="PrivacyPolicy_conatianer">
                                <p className="PrivacyPolicy_big_title">6. MODIFICATION DE LA PRESENTE POLITIQUE DE CONFIDENTIALITE </p>
                                <p className="PrivacyPolicy_pharagraphes">Les utilisateurs de l’application seront informés de toute modification ou mise à jour de cette  Politique de Confidentialité par un affichage sur l’application. </p>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;
