import React, { Component } from 'react'
import photo_empty from '../../Images/photo_empty_forCard.png'
import './PharmacienCard.css'
import { withRouter } from 'react-router-dom'
import { IMAGES_BASE_URL } from '../../config';


class PharmacienCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoDeCover: null
        }
    }

    componentWillMount() {
        this.props.item?.pictures.map(async (item, key) => {
            if (item?.pictureType === "cover") {
                this.setState({ photoDeCover: item })
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.item !== this.props.item) {
            console.log("been here");
            if (this.props.item?.pictures?.length !== 0) {
                this.props.item?.pictures.map(async (item, key) => {
                    if (item?.pictureType === "cover") {
                        this.setState({ photoDeCover: item })
                    }
                })
            } else {
                this.setState({ photoDeCover: null })
            }
        }
    }

    render() {
        return (
            <div className="PharmacienCard" onClick={() => {
                this.props.history.push(`/pharmacie/${this.props.item.slug}`)
                window.scrollTo(0, 0)
            }}>
                <div className="card">
                    <div className="PhotoAndtitleOfCard p-2">
                        {
                            this.state.photoDeCover ?
                                <img className="card-img-top" src={IMAGES_BASE_URL + this.state.photoDeCover?.pictureRef} title={this.props.item?.name} /> :
                                <img className="card-img-top" src={photo_empty} title={this.props.item?.name} />
                        }
                    </div>
                    <div className="PhotoAndtitleOfCard pl-2 PhotoAndtitleOfCard_text">
                        <a className="bloc_pharmacie_title">{this.props && this.props.item?.name}</a>
                    </div>
                    <div className="card-body Pharmacie_infos pt-2">
                        <ul>
                            {this.props.item?.showPharmacistName && <li><i className="fas fa-user IconStyle"></i><p><strong>Pharmacien : </strong></p><span>{this.props.item && this.props.item?.pharmacist ? (this.props.item?.pharmacist?.firstName + ' ' + this.props.item?.pharmacist?.lastName) : ""}</span></li>}
                            <li><i className="fas fa-city IconStyle"></i><p><strong>Ville :</strong> </p><span style={{ width: "60%" }}>{this.props.item.city && this.props.item.city.name}</span></li>
                            <li><i className="far fa-compass IconStyle"></i><p><strong>Secteur :</strong></p><span style={{ width: "50%" }}> {this.props.item.sector && this.props.item.sector.name}</span></li>
                        </ul>
                        <a className="btn btn-primary CardButton py-2 px-3 w-100" onClick={() => {
                            this.props.history.push(`/pharmacie/${this.props.item.slug}`)
                            window.scrollTo(0, 0)
                        }}>Plus d'Informations</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(PharmacienCard)