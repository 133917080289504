import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { LOGIN_PATIENT } from '../../config';
import Footer from '../../helpers/Footer/Footer';
import Header from '../../helpers/Header/Header';
import InfoPersonnelComponent from '../../helpers/InfoPersonnelComponent/InfoPersonnelComponent';
import Loader from '../../helpers/Loader';
import patientsService from '../../services/patients.service';

class MonCompteComponents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 1,
            authonticatedUser: null,
            favoritePharmacies: [],
            loader: true
        };
    }

    componentDidMount() {
        this.getCurrentPatient()
    }

    setActive = (i) => {
        this.setState({ active: i })
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ authonticatedUser: response.data, loader: false })
        } catch (error) {
            if (error.response?.status === 401) {
                window.location.href = LOGIN_PATIENT
            }
            console.log(error);
        }
    }

    render() {
        const { loader } = this.state
        return (
            <div className="MonCompteComponents">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine" />
                    <meta name="theme-color" content="#00c389" />
                </Helmet>
                {loader && <Loader />}
                <Header />
                <div className="MonCompteForm">
                    <div className="MonCompteFormHeader">
                        <h2 className="MonCompteFormTitle">MON COMPTE</h2>
                        <p className="MonCompteFormparagraphe">Vous pouvez modifier vos informations personnelles à tout moment et en toute simplicité.</p>
                    </div>

                    <div className="container MonCompteFormCorp">
                        <div className="col-10 offset-1">
                            <div className="row">
                                <div className="col-4 d-flex flex-column MonCompteDetailes">
                                    <p className="uiStateIsActive IsActive" onClick={() => this.props.history.push('/profile')}><i className="far fa-address-card mr-2"></i>Informations personnelles</p>
                                    <p className="IsActive" onClick={() => this.props.history.push('/favoritePharmacie')}><i className="far fa-star mr-2"></i>Pharmacies favorites</p>
                                    <p className="IsActive" onClick={() => this.props.history.push('/consultedPharmacie')}><i className="far fa-eye mr-2"></i>Pharmacies consultées</p>
                                    <p className="IsActive" onClick={() => this.props.history.push('/prescriptionPharmacie')}><i class="fas fa-file-medical-alt mr-2"></i>Mes ordonnances</p>
                                </div>
                                <div className="col-8">
                                    <InfoPersonnelComponent authonticatedUser={this.state.authonticatedUser} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default MonCompteComponents;