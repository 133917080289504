import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../../helpers/Footer/Footer';
import Header from '../../helpers/Header/Header';
import Error404 from '../../Images/error404.png'
import './ErrourPage.css'

class ErrourPage extends Component {
    render() {
        return (
            <div className="ErrourPage" >
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine " />
                </Helmet>
                <Header />
                <div className="ErreurComponent">
                    <div className="ErreurComponentHeader">
                        <h2 className="ErreurComponentTitle">ERREUR 404</h2>
                        <p className="ErreurComponentparagraphe">Cette page que vous recherchez n'existe pas.</p>
                    </div>
                    <div className="container ErreurComponentCorp">
                        <div className="col-10 offset-1">
                            <div className="row py-5">
                                <div className="col-8 offset-2 p-5 d-flex flex-column align-items-center">
                                    <img src={Error404} alt="RecomendationThanks" className="ErreurComponentImage mb-5" />
                                    <button type="button" onClick={() => this.props.history.push('/')} className="btn ErreurComponent_btn navbar-btn px-5 py-2">Retour à l'accueil</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default ErrourPage;
