import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { LOGIN_PATIENT } from '../../config';
import EditPresonnalInformation from '../../helpers/EditPresonnalInformation/EditPresonnalInformation';
import Footer from '../../helpers/Footer/Footer';
import Header from '../../helpers/Header/Header';
import Loader from '../../helpers/Loader';
import patientsService from '../../services/patients.service';
import './EditProfilePage.css'

class EditProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 1,
            authonticatedUser: null,
            updateEmail: false,
            loader: true
        };
        this.handleUpdateEmail = this.handleUpdateEmail.bind(this)
    }

    setActive = (i) => {
        this.setState({ active: i })
    }

    componentDidMount() {
        this.getCurrentPatient()
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ authonticatedUser: response.data, loader: false })
        } catch (error) {
            if (error.response?.status === 401) {
                window.location.href = LOGIN_PATIENT
            }
            console.log(error);
        }
    }

    handleUpdateEmail() {
        this.setState({ updateEmail: !this.state.updateEmail }, () => window.scrollTo(0, 0))
    }

    render() {
        const { authonticatedUser, updateEmail, loader } = this.state
        return (
            <div className="EditConCompteComponent">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content="Pharmacie de garde,Pharmacie,Officine" />
                </Helmet>
                {
                    updateEmail && <div className="notification">
                        <i className="fas fa-exclamation-triangle"></i>
                        <p>L'adresse e-mail est déjà utilisée.</p>
                    </div>
                }
                {loader && <Loader />}
                <Header />
                <div className="EditMonCompte">
                    <div className="EditMonCompteHeader">
                        <h2 className="EditMonCompteTitle">MON COMPTE</h2>
                        <p className="EditMonCompteparagraphe">Vous pouvez modifier vos informations personnelles à tout moment et en toute simplicité.</p>
                    </div>

                    <div className="container EditMonCompteCorp">
                        <div className="col-10 offset-1">
                            <div className="row">
                                <div className="col-4 d-flex flex-column EditMonCompteeDetailes">
                                    <p className={this.state.active === 1 ? "uiStateIsActive IsActive" : "IsActive"} onClick={() => this.setActive(1)}><i className="far fa-address-card mr-2"></i>Informations personnelles</p>
                                </div>
                                <div className="col-8">
                                    {
                                        this.state.active === 1 ? <EditPresonnalInformation handleUpdateEmail={this.handleUpdateEmail} /> : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default EditProfilePage;
