import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import favoritePharmaciesService from '../../services/favoritePharmacies.service';
import patientsService from '../../services/patients.service';
import './PharmacyConsulterComponent.css';

class PharmacyConsulterComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            consultedPharmacies: [],
            currentPage: 1,
            limit: 5,
            totalItems: null
        }
    }

    componentDidMount() {
        this.getConsultedPharmacies()
    }

    getConsultedPharmacies = async () => {
        const { currentPage, limit } = this.state
        try {
            let response = await patientsService.getConsultedPharmacies(currentPage, limit);
            this.setState({ consultedPharmacies: response.data.pharmacies, totalItems: response.data.total }, () => console.log(this.state.totalItems))
        } catch (error) {
            console.log(error);
        }
    }

    toggleFavoritePharmacies = async (slug, id) => {
        try {
            this.setState({ removeAnimation: true })
            await favoritePharmaciesService.toggleFavoritePharmacies(slug);
            this.setState({ removeAnimation: true, removeAnimationId: id }, () => setTimeout(() => {
                this.getConsultedPharmacies()
            }, 1000))
        } catch (error) {
            if (400 === error?.response?.status) {
                if (error.response.data.message === "you have already favored 3 pharmacies") {
                    window.scrollTo(0, 0);
                    this.props.fromChildToParentCallback(42)
                }
            }
        }
    }

    render() {
        const { consultedPharmacies, currentPage, totalItems, limit } = this.state
        return (
            <div className="PharmacyConsulterComponent">
                <div id="favorite-pharmacies" >
                    <table className="PharmacyConsulterTable">
                        <thead className="PharmacyConsulterThead">
                            <tr className="row">
                                <th className="col-4">Pharmacie</th>
                                <th className="col-4">Ville</th>
                                <th className="col-4"></th>
                            </tr>
                        </thead>
                        <tbody id="favorites" className="PharmacyConsulterTBody">
                            {
                                consultedPharmacies !== 0 && consultedPharmacies.map((item, i) => {
                                    return (
                                        <tr className="row" key={i}>
                                            <td className="col-4">{item.name}</td>
                                            <td className="col-4">{item.city && item.city.name}</td>
                                            <td className="col-4 d-flex flex-column align-items-baseline">
                                                {!item.favorite ?
                                                    <a className="AddFavorites" onClick={() => this.toggleFavoritePharmacies(item?.slug, item?.id)}><i className="fas fa-plus mr-2" /> Ajouter à mes favoris</a> :
                                                    <a className="deleteFavorites" onClick={() => this.toggleFavoritePharmacies(item?.slug, item?.id)}><i className="fas fa-minus mr-2" /> Retirer de mes favoris</a>
                                                }
                                                <a className="viewFavorites" onClick={() => window.open(`/pharmacie/${item.slug}`)}><i className="far fa-eye mr-2"></i>Voir la pharmacie  </a>
                                            </td>
                                        </tr>)
                                })
                            }
                            <div className="PharmacyConsulterPagination">
                                <div className="Paginationbutton d-flex align-content-center">
                                    <ul className="pagination">
                                        <li className="page-item">
                                            {currentPage > 1 && (
                                                <a className="page-link" onClick={() => this.setState({
                                                    currentPage: currentPage - 1
                                                }, () => this.getConsultedPharmacies())} aria-label="Previous">
                                                    <span aria-hidden="true"><i className="fas fa-chevron-left pagination_icon"></i></span>
                                                </a>
                                            )}
                                        </li>
                                        <li className="page-item"><a className="page-link">{currentPage}</a></li>
                                        <li className="page-item">
                                            {
                                                currentPage < totalItems / limit && (
                                                    <a className="page-link" onClick={() => this.setState({
                                                        currentPage: currentPage + 1
                                                    }, () => this.getConsultedPharmacies())
                                                    } aria-label="Previous">
                                                        <span aria-hidden="true"><i className="fas fa-chevron-right pagination_icon"></i></span>
                                                    </a>)
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withRouter(PharmacyConsulterComponent);
