import React, { Component } from 'react';
import Footer from '../../helpers/Footer/Footer';
import Loader from '../../helpers/Loader';
import PharmacieInfoComponent from '../../helpers/PharmacieInfoComponent/PharmacieInfoComponent';
import PharmacienCard from '../../helpers/PharmacienCard/PharmacienCard';
import patientsService from '../../services/patients.service';
import pharmaciesServices from '../../services/pharmacies.services';
import './PharmacieInfoPage.css';

class PharmacieInfoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authonticatedUser: null,
            pharmacieCity: null,
            Pharmacies: [],
            slug: null,
            loader: true,
            pharmacieData: null
        };
    }

    componentDidMount() {
        document.title = `${this.state.pharmacieData?.name ? (this.state.pharmacieData.name + " sur") : ""} MonPharmacien - 1er annuaire interactif de pharmacies au Maroc`
        const slug = this.props.match && this.props.match.params.slug;
        this.setState({ slug })
        this.getCurrentPatient()
        this.getPharmacieByslug(slug);
    }

    componentDidUpdate(prevState) {
        const slug = this.props.match && this.props.match.params.slug;
        if (this.state.slug !== slug) {
            this.setState({ slug })
            this.getPharmacieByslug(slug);
            this.getAllPharmacies()
        }
        if (prevState.pharmacieData !== this.state.pharmacieData) {
            document.title = `${this.state.pharmacieData?.name ? (this.state.pharmacieData.name + " sur") : ""} MonPharmacien - 1er annuaire interactif de pharmacies au Maroc`
        }
    }


    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ authonticatedUser: response.data })
        } catch (error) {
            console.log(error);
        }
    }

    getPharmacieByslug = async (slug) => {
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            const action = {
                action: "Consultation"
            }
            this.setState({ pharmacieCity: response.data.pharmacy?.city?.id, loader: false, pharmacieData: response.data.pharmacy }, () => {
                this.getAllPharmacies()
            })
        } catch (error) {
            if (404 === error.response.status) {
                this.props.history.push('/error')
            }
            console.log(error);
        }
    }

    getAllPharmacies = async () => {
        try {
            let data = {
                name: '',
                cityId: this.state.pharmacieCity,
                sectorId: ""
            }
            let response = await pharmaciesServices.getAllPharmacies(1, 4, data);
            const newArray = []
            response.data.pharmacies.length !== 0 && response.data?.pharmacies.map((item, i) => {
                if (item.slug !== this.state.slug) {
                    newArray.push(item)
                }
                this.setState({ Pharmacies: newArray })
            });
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        const { authonticatedUser, Pharmacies, loader } = this.state
        return (
            <div className="pharmacieInfoPage">
                {loader && <Loader />}
                <PharmacieInfoComponent />
                {
                    Pharmacies.length !== 0 &&
                    <div style={{ backgroundColor: '#f5f9fd' }}>
                        <div className="LastPharmacies container p-3" style={{ backgroundColor: '#f5f9fd' }}>
                            <div className="col-10 offset-1">
                                <div className="row">
                                    <div className="col-8 offset-2 py-3">
                                        <h2 className=" text-uppercase LastPharmaciesTitle">LES PHARMACIES LES PLUS PROCHES</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    {this.state.Pharmacies.map((item, i) => {
                                        return <div className="LastPharmaciesCards col-3 ">
                                            <PharmacienCard item={item} key={i} />
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <Footer />
            </div>
        );
    }
}

export default PharmacieInfoPage;