import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ModalLayout from '../../ModalLayout/ModalLayout';
import google_icon from '../../../Images/google_icon.png';
import { LOGIN_PATIENT, SIGNUP_PATIENT } from '../../../config';
import './LoginModal.css';

class LoginModal extends Component {
    render() {
        return (
            <ModalLayout title="Veuillez vous connecter" lg={false} bodyParagraphe="Cette action nécessite que vous soyez connecté. Connectez-vous ou inscrivez-vous si vous n'avez pas de compte." id="LoginModal">
                <div className="AllButtonsInLoginModal mx-auto">
                    <div className="LoginOrRegister d-flex flex-column">
                        <button type="button" className="btn btn-primary LoginModalButton my-2" data-dismiss="modal" onClick={() => window.open(LOGIN_PATIENT)} >Se connecter</button>
                        <button type="button" className="btn btn-primary RegisterModalButton my-2" data-dismiss="modal" onClick={() => window.open(SIGNUP_PATIENT)} >S'inscrire</button>
                    </div>
                    {/* <div className="LoginModalButtons py-3">
                        <div className=" align-baseline">
                            <div className="border-left"></div>
                            <span className="OautoSpan">Ou</span>
                            <div className="border-right"></div>
                        </div>
                        <div className="LoginModalOautButtons d-flex flex-column my-4">
                            <button type="button" className="btn btn-primary LoginFormButtonFacebook my-1 py-1" onClick={() => window.location.href = 'https://api.monpharmacien.sobrus.ovh/api/app/connect/facebook'}>
                                <i className="fab fa-facebook-square facebook_icon" />Se connecter avec facebook</button>
                            <button type="button" className="btn btn-primary LoginFormButtonGoogle my-1 py-1" onClick={() => window.location.href = 'https://api.monpharmacien.sobrus.ovh/api/app/connect/google'}>
                                <img src={google_icon} className="google_icon" />Se connecter avec google</button>
                        </div>
                    </div> */}
                </div>
            </ModalLayout>
        );
    }
}

export default withRouter(LoginModal);