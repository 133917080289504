import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import InscriptionIllustration from '../../Images/inscription_illustration.png'
import patientsService from '../../services/patients.service'
import { LOGIN_PHARMACIST } from '../../config'
import './AboutUs.css';

class AboutUs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            authonticatedUser: null
        }
    }

    componentDidMount() {
        this.getCurrentPatient()
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ authonticatedUser: response.data })
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { authonticatedUser } = this.state
        return (
            <>
                {
                    authonticatedUser ?
                        "" :
                        <div className="AboutUs ">
                            <div className="container ">
                                <div className="col-10 offset-1 d-flex py-5">
                                    <div className="row">
                                        <div className="col-8 py-3">
                                            <h2 className="AboutUsTitle">Vous êtes un pharmacien d'officine ?</h2>
                                            <p className="AboutUsParagraphe">L'objectif de MonPharmacien est d'améliorer la relation pharmacien-patient, pour un meilleur accès aux soins et un meilleur suivi des patients auprès de leur pharmacie d'officine</p>

                                            <p className="AboutUsParagraphe">Inscrivez-vous gratuitement dès aujourd'hui pour vous rapprocher de vos patients.</p>
                                            <Link onClick={() => window.open(LOGIN_PHARMACIST)} >
                                                <a className="btn btn-primary AboutUsButton mt-4">Inscrivez-vous dès maintenant</a>
                                            </Link>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 ">
                                            <img src={InscriptionIllustration} alt="inscription" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </>
        )
    }
}

export default AboutUs