import axios from "axios";
import {api_url} from "../config";

const authAxios = axios.create({
    baseURL: api_url + "app/pharmacies"
});

class pharmaciesServices {
    constructor() {
        authAxios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
    }

    getAllPharmacies(page, limit, data) {
        let params = {
            page: page,
            limit: limit
        }
        return authAxios.post(`/get`, data, { params, withCredentials: true });
    }

    getPharmacieById(id) {
        return authAxios.get(`/${id}`, { withCredentials: true });
    }

    getPharmacieByslug(slug) {
        return authAxios.get(`/${slug}`, { withCredentials: true });
    }

    getPharmacieLastSynchronization() {
        return authAxios.get(`/last-sync/infos`, { withCredentials: true });
    }

    creatPharmacien(data) {
        return authAxios.post(`/suggest`, data, { withCredentials: true });
    }

    seggestOwnerShip(data){
        return authAxios.post(`/ownership/suggest`, data, { withCredentials: true });
    }
}

export default new pharmaciesServices()