import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { loadReCaptcha } from 'react-recaptcha-google';
import { isMobile } from 'react-device-detect';
import LandingPage from './pages/LandingPage/LandingPage';
import ContactPage from './pages/ContactPage/ContactPage';
import ErrourPage from './pages/ErrourPage/ErrourPage';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SearchErrourPage from './pages/SearchErrourPage/SearchErrourPage';
import SearchResultPage from './pages/SearchResultPage/SearchResultPage';
import EditProfilePage from './pages/EditProfilePage/EditProfilePage';
import ConsultedPharmacie from './pages/ConsultedPharmacie/ConsultedPharmacie';
import favoritePharmacie from './pages/favoritePharmacie/favoritePharmacie';
import MonCompteComponents from './pages/MonCompteComponents/MonCompteComponents';
import PharmacieInfoPage from './pages/PharmacieInfoPage/PharmacieInfoPage';
import PrescriptionPharmacie from './pages/PrescriptionPharmacie/PrescriptionPharmacie';
import SuccessComponent from './helpers/AuthComponent/SuccessComponent';
import failureComponent from './helpers/AuthComponent/failureComponent';
import CompleteRegistration from './pages/CompleteRegistration/CompleteRegistration';
import store from './Store/configStore';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import CookieConsent from 'react-cookie-consent';
import { Helmet } from 'react-helmet';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './all CSS/allCss.css';
import './App.css';

function App() {
  useEffect(() => {
    loadReCaptcha();
  });

  if (isMobile) {
    const url = window.location.href;
    const newUrl = url.replace('https://', 'https://m.');
    return window.location.href = newUrl;
  } else {
    return (
      <>
        {/* <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
          <meta name="description" content="1er annuaire interactif de pharmacies
           au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de
            garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
          <meta name="theme-color" content="#00c389" />
          <meta name="keywords" content="Pharmacie de garde, Pharmacie, Officine" />
        </Helmet> */}
        <Provider store={store} >
          <BrowserRouter>
            <div className="App">
              <Switch>
                <Route path='/' exact component={LandingPage} />

                <Route path='/contact' exact component={ContactPage} />
                <Route path='/pharmacie/:slug' exact component={PharmacieInfoPage} />

                <Route path='/profile' exact component={MonCompteComponents} />
                <Route path='/profile/edit' exact component={EditProfilePage} />

                <Route path='/favoritePharmacie' exact component={favoritePharmacie} />
                <Route path='/consultedPharmacie' exact component={ConsultedPharmacie} />
                <Route path='/complete_registration' exact component={CompleteRegistration} />

                <Route path='/prescriptionPharmacie' exact component={PrescriptionPharmacie} />

                <Route path='/pharmacies' exact component={SearchResultPage} />
                <Route path='/noSearch' exact component={SearchErrourPage} />

                <Route path='/privacy_policy' exact component={PrivacyPolicy} />

                <Route path='/auth/success' exact component={SuccessComponent} />
                <Route path='/auth/failed' exact component={failureComponent} />

                <Route exact component={ErrourPage} />
              </Switch>
            </div>
          </BrowserRouter>
        </Provider>

        <CookieConsent
          location="bottom"
          cookieName="CookieConsent"
          buttonText="Accepter tous les cookies"
          declineButtonText="Refuser tous les cookies"
          enableDeclineButton={true}
          flipButtons={true}
          style={{
            background: "#52747D",
            borderRadius: "8px 8px 0px 0px",
            paddingBottom: "16px",
            paddingLeft: "100px",
            display: "block",
            textAlign: "start",
            boxSadow: "3px 0px 10px #A9BABF"
          }}
          buttonStyle={{
            color: "#ffffff",
            fontSize: "14px",
            fontFamily: "openSans-Light",
            backgroundColor: "#f6ac32",
            padding: "11px 38px",
            borderRadius: "6px"
          }}
          declineButtonStyle={{
            color: "#f6ac32",
            fontSize: "14px",
            fontFamily: "openSans-Light",
            border: "1px solid #f6ac32",
            backgroundColor: "transparent",
            padding: "11px 38px",
            borderRadius: "6px"
          }}
          expires={150}
        >
          <p className="cookiePopup_title">Ce site utilise des cookies 🍪</p>
          <p className="cookiePopup_paragraph" style={{ marginBottom: '0px' }}>Sobrus utilise des cookies afin d’optimiser votre expérience lors de votre navigation.</p>
          <p className="cookiePopup_paragraph">Ces cookies  sont utilisés à des fins de bon fonctionnement du site, de sécurité, de mesure d’audience, d’analyse, d’amélioration de l’expérience utilisateur ainsi qu’à des fins publicitaires. Votre consentement à l’installation de cookies non strictement nécessaires est libre et peut être retiré à tout moment. Vous pouvez donner ou retirer votre consentement globalement ou paramétrer vos préférences par finalité de cookies. </p>

        </CookieConsent>
      </>
    );
  }
}

export default App;
