var api_url = "https://api.monpharmacien.sobrus.ovh/api/";
let MANAGE_PROFILE_URL = "https://account.sobrus.ovh/account";
let LOGIN_PHARMACIST = "https://pro.monpharmacien.sobrus.ovh/";
let LOGIN_PATIENT = "https://account.sobrus.ovh/login?redirectUri=https%3A%2F%2Fapi.account.sobrus.ovh%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3D10051%26scope%3Dopenid%2520offline_access%2520profile%2520email%26redirect_uri%3Dhttps%3A%2F%2Fapi.monpharmacien.sobrus.ovh%2Fauth%2Fpatient%2Ftoken%26prompt%3Dlogin";
let SIGNUP_PATIENT = "https://account.sobrus.ovh/signup?redirectUri=https%3A%2F%2Fapi.account.sobrus.ovh%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3D10051%26scope%3Dopenid%2520offline_access%2520profile%2520email%26redirect_uri%3Dhttps%3A%2F%2Fapi.monpharmacien.sobrus.ovh%2Fauth%2Fpatient%2Ftoken%26prompt%3Dlogin";
let LINK_PHARMA_PRO = "http://pro.monpharmacien.sobrus.ovh/";
let IMAGES_BASE_URL = "https://api.monpharmacien.sobrus.ovh/images/pharmacies/";
let IMAGES_BASE_URL_PRESCRIPRION = "https://cnc.sobrus.ovh/uploaded_files/prescriptions/";

if (process.env.REACT_APP_ENV === "development") {
    api_url = "https://api.monpharmacien.sobrus.ovh/api/";
    MANAGE_PROFILE_URL = "https://account.sobrus.ovh/account";
    LOGIN_PHARMACIST = "https://pro.monpharmacien.sobrus.ovh/";
    LOGIN_PATIENT = "https://account.sobrus.ovh/login?redirectUri=https%3A%2F%2Fapi.account.sobrus.ovh%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3D10051%26scope%3Dopenid%2520offline_access%2520profile%2520email%26redirect_uri%3Dhttps%3A%2F%2Fapi.monpharmacien.sobrus.ovh%2Fauth%2Fpatient%2Ftoken%26prompt%3Dlogin";
    SIGNUP_PATIENT = "https://account.sobrus.ovh/signup?redirectUri=https%3A%2F%2Fapi.account.sobrus.ovh%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3D10051%26scope%3Dopenid%2520offline_access%2520profile%2520email%26redirect_uri%3Dhttps%3A%2F%2Fapi.monpharmacien.sobrus.ovh%2Fauth%2Fpatient%2Ftoken%26prompt%3Dlogin";
    LINK_PHARMA_PRO = "http://pro.monpharmacien.sobrus.ovh/";
    IMAGES_BASE_URL = "https://api.monpharmacien.sobrus.ovh/images/pharmacies/";
    IMAGES_BASE_URL_PRESCRIPRION = "https://cnc.sobrus.ovh/uploaded_files/prescriptions/";
}

if (process.env.REACT_APP_ENV === "production") {
    api_url = "https://api.monpharmacien.sobrus.com/api/";
    MANAGE_PROFILE_URL = "https://account.sobrus.com/account";
    LOGIN_PHARMACIST = "https://pro.monpharmacien.sobrus.com/";
    LOGIN_PATIENT = "https://account.sobrus.com/login?redirectUri=https%3A%2F%2Fapi.account.sobrus.com%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3D10051%26scope%3Dopenid%2520offline_access%2520profile%2520email%26redirect_uri%3Dhttps%3A%2F%2Fapi.monpharmacien.sobrus.com%2Fauth%2Fpatient%2Ftoken%26prompt%3Dlogin";
    SIGNUP_PATIENT = "https://account.sobrus.com/signup?redirectUri=https%3A%2F%2Fapi.account.sobrus.com%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3D10051%26scope%3Dopenid%2520offline_access%2520profile%2520email%26redirect_uri%3Dhttps%3A%2F%2Fapi.monpharmacien.sobrus.com%2Fauth%2Fpatient%2Ftoken%26prompt%3Dlogin";
    LINK_PHARMA_PRO = "http://pro.monpharmacien.sobrus.com/";
    IMAGES_BASE_URL = "https://api.monpharmacien.sobrus.com/images/pharmacies/";
    IMAGES_BASE_URL_PRESCRIPRION = "https://cnc.sobrus.com/uploaded_files/prescriptions/";
}

export { api_url, LOGIN_PHARMACIST, LOGIN_PATIENT, MANAGE_PROFILE_URL, SIGNUP_PATIENT, LINK_PHARMA_PRO, IMAGES_BASE_URL, IMAGES_BASE_URL_PRESCRIPRION };
