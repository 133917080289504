import React, { Component } from 'react';
import patientsService from '../../../services/patients.service';
import ModalLayout from '../../ModalLayout/ModalLayout';
import './SecteurModal.css';

class SecteurModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancellationReasons: [],
            cancellationReason: null
        }
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.getCancellationReasons()
    }

    getCancellationReasons = async () => {
        try {
            let response = await patientsService.getCancellationReasons();
            this.setState({ cancellationReasons: response.data?.cancellation_reasons })
        } catch (error) {
            console.log(error);
        }
    }

    caancelPrescrirption = async (cancellationReasonId) => {
        try {
            await patientsService.caancelPrescrirption(cancellationReasonId, this.props.prescriptionId);
            this.getAllPrescriptions();
        } catch (error) {
            console.log(error);
        }
    }

    handleChange(e) {
        this.setState({ cancellationReason: e.target.value })
    }

    render() {
        const { cancellationReasons, cancellationReason } = this.state
        return (
            <ModalLayout title="Raison d’annulation" lg={false} cancelBtn={{ text: "Annuler" }} bodyParagraphe="Pourquoi souhaitez-vous annuler votre ordonnance ?" id="cancellationReasons">
                <div className="form-group SecteurModalInputs">
                    <label for="sel1">Raison d’annulation :</label>
                    <select className="form-control" id="sel1" name="sellist1" onChange={this.handleChange} value={cancellationReason}>
                        {
                            cancellationReasons.map((item, key) => {
                                return <option value={item?.id} key={key}>{item?.reason}</option>
                            })
                        }
                    </select>
                </div>
                <button className="btn-primary sendBtn mt-3 w-100" data-dismiss="modal" data-toggle="modal" onClick={() => this.caancelPrescrirption(cancellationReason)} >Envoyer</button>
            </ModalLayout>
        );
    }
}

export default SecteurModal;