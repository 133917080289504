import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MANAGE_PROFILE_URL } from '../../config';
import citiesService from '../../services/cities.service';
import patientsService from '../../services/patients.service';
import SearchSelect from '../AsyncSelect';
import './EditPresonnalInformation.css';

class EditPresonnalInformation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            authonticatedUser: null,
            fields: {
                ...this.initialFields,
            },
            errors: {},
            cities: [],
            sectors: [],
            sectorId: null,
            cityId: null,
        }
        this.handleChange = this.handleChange.bind(this)
    }

    initialFields = {
        lastName: "",
        firstName: "",
        email: "",
        password: "",
        mobileNumber: "",
        password: "",
        country: "",
        zipcode: "",
        city: {
            id: null
        },
        sector: {
            id: null
        }
    }

    componentWillMount() {
        this.getCurrentPatient()
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            console.log(response.data);
            let prepareData = {
                ...response.data,
                city: response.data?.patientData?.city?.id,
                sector: response.data?.patientData?.sector?.id,
                country: response.data?.patientData?.country,
                zipcode: response.data?.patientData?.zipcode,
            }
            let city = {
                id: response.data?.patientData?.city?.id
            }
            let sector = {
                id: response.data?.patientData?.sector?.id
            }
            this.setState({
                fields: prepareData,
                selectedCity: {
                    value: response.data?.patientData?.city?.id,
                    label: response.data?.patientData?.city?.name
                },
                selectedSector: {
                    value: response.data?.patientData?.sector?.id,
                    label: response.data?.patientData?.sector?.name
                },
                cityId: city,
                sectorId: sector
            })
        } catch (error) {
            console.log(error);
        }
    }

    handleValidation = async () => {
        const { cityId } = this.state;
        let errors = {};
        let formIsValid = true;

        if (cityId === null) {
            formIsValid = false;
            errors['cityId'] = 'Ce champ est requis'
        }
        
        this.setState({ errors: { ...this.state.errors, ...errors, } });
        return formIsValid;
    };

    handleChange = (input) => (e) => {
        let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
        fieldsCopy[input] = e.target.value;
        this.setState({ fields: fieldsCopy, cityId: fieldsCopy.city }, () => {
            if (this.state.fields.country === "ma") {
                let newFieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
                newFieldsCopy = {
                    ...this.state.fields,
                    zipcode: null
                }
                this.setState({ fields: newFieldsCopy })
            }

            if (input === "country") {
                this.setState({
                    cityId: null,
                    sectorId: null,
                    selectedCity: null,
                    selectedSector: null
                }, () => console.log(this.state.fields))
            }
        });
    };

    updatePatient = async () => {
        this.setState({ errors: {} }, async () => {
            let valid = await this.handleValidation();
            if (valid) {
                try {
                    let fieldsCopy = this.state.fields
                    fieldsCopy = {
                        city: {
                            id: this.state.cityId
                        },
                        sector: {
                            id: this.state.sectorId
                        },
                        country: this.state.fields.country,
                        zipcode: this.state.fields.zipcode
                    }
                    await patientsService.updatePatient(fieldsCopy);
                    this.props.history.push('/profile')
                } catch (error) {
                    if (400 === error?.response?.status) {
                        if (error.response.data.message === "provided email already exists") {
                            return this.props.handleUpdateEmail()
                        }
                    }
                }
            }
        });
    }

    render() {
        const { fields, errors, cities, sectors } = this.state;
        return (
            <>
                <div className="EditPresonnalInformation mb-4">
                    <p className="EditPresonnalInformation_title">Informations générales</p>
                    <div className="d-flex row" id="personal-informations" >
                        <div className="col-6 ">
                            <div className="form-group">
                                <label className=" EditPresonnalInformationLabels">Nom :</label>
                                <input type="text" className="form-control EditPresonnalInformationInputs" id="exampleInputNom" aria-describedby="emailHelp" placeholder="Entrez voter nom" error={errors["lastName"]} onChange={this.handleChange("lastName")} value={fields["lastName"]} disabled />
                                {errors["lastName"] ? <p className="erreurInputText"><><i className="far fa-times-circle"></i> {errors["lastName"]}</> </p> : ""}
                            </div>
                            <div className="form-group">
                                <label className=" EditPresonnalInformationLabels">Prénom :</label>
                                <input type="text" className="form-control EditPresonnalInformationInputs" id="exampleInputPrenom" placeholder="Entrez voter prénom" error={errors["firstName"]} onChange={this.handleChange("firstName")} value={fields["firstName"]} disabled />
                                {errors["firstName"] ? <p className="erreurInputText"> <><i className="far fa-times-circle"></i> {errors["firstName"]}</> </p> : ""}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label className=" EditPresonnalInformationLabels">Téléphone :</label>
                                <input type="text" className="form-control EditPresonnalInformationInputs" id="exampleInputTelephone" placeholder="Entrez votre téléphone" error={errors["mobileNumber"]} onChange={this.handleChange("mobileNumber")} value={fields["mobileNumber"]} disabled />
                                {errors["mobileNumber"] ? <p className="erreurInputText"> <><i className="far fa-times-circle"></i> {errors["mobileNumber"]}</></p> : ""}
                            </div>
                            <div className="form-group">
                                <label className=" EditPresonnalInformationLabels">Email :</label>
                                <input type="email" className="form-control EditPresonnalInformationInputs" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Entrez votre email" error={errors["email"]} onChange={this.handleChange("email")} value={fields["email"]} disabled />
                                {errors["email"] ? <p className="erreurInputText"><><i className="far fa-times-circle"></i> {errors["email"]}</></p> : ""}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn EditPresonnalInformationBtn" onClick={() => window.open(MANAGE_PROFILE_URL)}>Gérer mon compte</button>
                    </div>
                </div>
                <div className="EditPresonnalInformation">
                    <p className="EditPresonnalInformation_title">Adresse</p>
                    <div className="d-flex row" id="personal-informations" >
                        <div className="col-6 ">
                            <div className="form-group">
                                <label className=" EditPresonnalInformationLabels">Ville :</label>
                                <SearchSelect
                                    className="form-control EditPresonnalInformationInputs"
                                    fetchedUrl='/cities'
                                    HTTPMethod="getcities"
                                    receivedObject="cities"
                                    optionLabelProperty="name"
                                    optionValueProperty="id"
                                    country={fields.country}
                                    value={this.state.selectedCity}
                                    placeholder="Entrez la ville"
                                    onChange={(e) => {
                                        if (e) {
                                            this.setState({ selectedCity: [], cities: [] }, () => {
                                                let city = {
                                                    id: e.value
                                                }
                                                this.state.cityId = city;
                                                this.setState({
                                                    cityId: city,
                                                    selectedCity: [...this.state.selectedCity, e],
                                                    selectedSector: null
                                                }, () => console.log(this.state.selectedCity))
                                            })
                                        }
                                    }}
                                    styles={{
                                        loadingIndicator: (provided, state) => ({
                                            ...provided,
                                            display: "none",
                                        }),
                                        indicatorSeparator: (provided, state) => ({
                                            ...provided,
                                            display: "none",
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                        }),
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderWidth: "2px",
                                            borderRadius: "none",
                                            boxShadow: "none",
                                            height: "51px",
                                            borderRadius: "3px",
                                            borderColor: "#e5eef7",
                                            ":hover": {
                                                borderColor: "none",
                                                borderWidth: "2px",
                                            },
                                        }),
                                        input: (provided, state) => ({
                                            ...provided,
                                            fontFamily: "OpenSans",
                                            fontWeight: "600",
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected && "#ffff",
                                            fontFamily: "OpenSans",
                                            borderBottom: " 1px solid #e5eef7",
                                            padding: "5px",
                                            color: "#52747d",
                                            fontSize: "13px",
                                            textAlign: 'start',
                                            ":hover": {
                                                color: "#fff",
                                                backgroundColor: "#1bc48a",
                                            },
                                        }),
                                        clearIndicator: (provided, state) => ({
                                            ...provided,
                                            paddingRight: 0,
                                        }),
                                        dropdownIndicator: (provided, state) => ({
                                            ...provided,
                                            paddingLeft: 0,
                                        })
                                    }}
                                />
                                {errors["cityId"] ? <p className="erreurInputText"><><i className="far fa-times-circle"></i> {errors["cityId"]}</></p> : ""}
                            </div>
                            <div className="form-group">
                                <label className=" EditPresonnalInformationLabels">Pays :</label>
                                <select className="form-control EditPresonnalInformationInputs" onChange={this.handleChange("country")} value={fields["country"]} >
                                    <option value="dj" >Djibouti</option>
                                    <option value="ma" >Maroc</option>
                                    <option value="sn" >Sénégal</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            {fields.country === "ma" && <div className="form-group">
                                <label className=" EditPresonnalInformationLabels">Secteur :</label>
                                <SearchSelect
                                    className="form-control EditPresonnalInformationInputs"
                                    fetchedUrl='/sectors'
                                    HTTPMethod="getsectors"
                                    receivedObject="sectors"
                                    optionLabelProperty="name"
                                    optionValueProperty="id"
                                    country={fields.country}
                                    cityId={this.state.cityId?.id}
                                    value={this.state.selectedSector}
                                    placeholder="Entrez le secteur"
                                    onChange={(e) => {
                                        if (e) {
                                            this.setState({ selectedSector: [], cities: [] }, () => {
                                                let sector = {
                                                    id: e.value
                                                }
                                                this.state.sectorId = sector;
                                                this.setState({
                                                    sectorId: sector,
                                                    selectedSector: [...this.state.selectedSector, e]
                                                })
                                            })
                                        }
                                    }}
                                    styles={{
                                        loadingIndicator: (provided, state) => ({
                                            ...provided,
                                            display: "none",
                                        }),
                                        indicatorSeparator: (provided, state) => ({
                                            ...provided,
                                            display: "none",
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                        }),
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderWidth: "2px",
                                            borderRadius: "none",
                                            boxShadow: "none",
                                            height: "51px",
                                            borderRadius: "3px",
                                            borderColor: "#e5eef7",
                                            ":hover": {
                                                borderColor: "none",
                                                borderWidth: "2px",
                                            },
                                        }),
                                        input: (provided, state) => ({
                                            ...provided,
                                            fontFamily: "OpenSans",
                                            fontWeight: "600",
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected && "#ffff",
                                            fontFamily: "OpenSans",
                                            borderBottom: " 1px solid #e5eef7",
                                            padding: "5px",
                                            color: "#52747d",
                                            fontSize: "13px",
                                            textAlign: 'start',
                                            ":hover": {
                                                color: "#fff",
                                                backgroundColor: "#1bc48a",
                                            },
                                        }),
                                        clearIndicator: (provided, state) => ({
                                            ...provided,
                                            paddingRight: 0,
                                        }),
                                        dropdownIndicator: (provided, state) => ({
                                            ...provided,
                                            paddingLeft: 0,
                                        })
                                    }}
                                />
                            </div>}
                            {fields.country !== "ma" && <div className="form-group">
                                <label className=" EditPresonnalInformationLabels">Code postal :</label>
                                <input type="email" className="form-control EditPresonnalInformationInputs" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Entrez votre code postal" onChange={this.handleChange("zipcode")} value={fields["zipcode"]} />
                            </div>}
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn EditPresonnalInformationButton mr-3" onClick={() => this.props.history.push('/profile')}>Annuler</button>
                        <button type="button" className="btn EditPresonnalInformationBtn" onClick={() => this.updatePatient()}>Enregistrer</button>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(EditPresonnalInformation);
