import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import pharmaciesServices from '../../services/pharmacies.services'
import PharmacienCard from '../PharmacienCard/PharmacienCard'
import './LastPharmacies.css'

class LastPharmacies extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Pharmacies: []
        }
    }

    componentDidMount() {
        this.getAllPharmacies()
    }

    getAllPharmacies = async () => {
        try {
            let response = await pharmaciesServices.getAllPharmacies(1, 4, '', '', '');
            this.setState({ Pharmacies: response.data.pharmacies });
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        return (
            <div className="LastPharmacies container p-3">
                <div className="col-10 offset-1">
                    <div className="row">
                        <div className="col-8 offset-2 py-3">
                            <h2 className=" text-uppercase LastPharmaciesTitle">Les dernieres pharmacies inscrites</h2>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.Pharmacies.map((item, i) => {
                            return <div className="LastPharmaciesCards col-3 ">
                                <PharmacienCard item={item} key={i} />
                            </div>
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(LastPharmacies)