import React, { Component } from 'react';
import addPic from '../../Images/addPic.png';
import emptyGalerie from '../../Images/emptyGalerie.png';
import Carousel, { Modal, ModalGateway } from 'react-images';
import './PhotoGalerieRempliComponent.css';
import { IMAGES_BASE_URL } from '../../config';

const gutter = 2;

const Gallery = (props) => (
    <div className="row" style={{ marginLeft: -gutter, marginRight: -gutter }} {...props} />
);

const Image = (props) => (
    <div style={{ backgroundColor: "#eee" }} className="PhotoGalerieRempliImage" {...props} />
);

class PhotoGalerieRempliComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lightboxIsOpen: false,
            selectedIndex: 0
        }
    }

    toggleLightbox = (selectedIndex) => {
        this.setState((state) => ({
            lightboxIsOpen: !state.lightboxIsOpen,
            selectedIndex,
        }));
    };

    render() {
        const { lightboxIsOpen, selectedIndex } = this.state;
        const images = this.props.photoDePharmacy.map((item, j) => {
            return {
                source: IMAGES_BASE_URL + item?.pictureRef,
                caption: item.pictureTitle,
            };
        });
        return (
            <>
                {this.props.photoDePharmacy.length ?
                    <div>
                        <div className="PhotoGalerieRempliComponent p-4">
                            <Gallery>
                                {this.props.photoDePharmacy?.map((item, j) => {
                                    return (
                                        <Image key={j}>
                                            <img
                                                alt={item.pictureTitle}
                                                src={IMAGES_BASE_URL + item?.pictureRef}
                                                style={{
                                                    objectFit: "cover",
                                                    cursor: "pointer",
                                                    width: "100%",
                                                    height: "auto",
                                                }}
                                            />
                                            <div className="middle">
                                                <button className="btn btn-primary PharmacyImageButton" onClick={() => this.toggleLightbox(j)} >Voir la photo</button>
                                            </div>
                                        </Image>
                                    )
                                }) ?? <div className="PhotoGalerieRempliImage col-lg-4 col-md-6 mb-2" id="PhotoGalerieImage">
                                        <img src={addPic} alt="monpharmacien.ma logo" id="PharmacyImage" className="w-100" />
                                        <div className="middle">
                                            <button className="btn btn-primary PharmacyImageButton">Voir la photo</button>
                                        </div>
                                    </div>}
                            </Gallery>
                        </div>
                        <ModalGateway>
                            {lightboxIsOpen ? (
                                <Modal onClose={this.toggleLightbox}>
                                    <Carousel
                                        // components={{ FooterCaption }}
                                        currentIndex={selectedIndex}
                                        // formatters={{ getAltText }}
                                        views={images}
                                    />
                                </Modal>
                            ) : null}
                        </ModalGateway>
                    </div>
                    :
                    <div className="PhotoGalerieRempliComponent p-4">
                        <div className="text-center m-3">
                            <img src={emptyGalerie} className="emptyGalerie" />
                            <div>
                                <p className="emptyGalerie_title">Aucune photo</p>
                                <p className="emptyGalerie_paragraphe">Aucune photo pour le moment</p>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }

}

export default PhotoGalerieRempliComponent;
