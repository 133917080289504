import axios from "axios";
import {api_url} from "../config";

const authAxios = axios.create({
    baseURL: api_url + "app/patients/favorite-pharmacies"
});

class favoritePharmaciesService {
    constructor() {
        authAxios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
    }

    addFavoritePharmacies(id) {
        return authAxios.post(`${id}/add`, '', { withCredentials: true });
    }

    removeFavoritePharmacies(id) {
        return authAxios.post(`${id}/remove`, '',{ withCredentials: true });
    }

    toggleFavoritePharmacies(slug) {
        return authAxios.post(`${slug}/toggle`, '',{ withCredentials: true });
    }

    getFavoritePharmacies(page, limit) {
        let params = {
            page: page,
            limit: limit
        }
        return authAxios.get(``, { params, withCredentials: true });
    }
}

export default new favoritePharmaciesService()