import axios from "axios";
import { api_url } from "../config";

const authAxios = axios.create({
    baseURL: api_url + "app/patients"
});


class patientsService {
    constructor() {
        authAxios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
        this.authenticated = false;
    }

    updatePatient(data) {
        return authAxios.post(`/register`, data, { withCredentials: true });
    }

    AddrecommendedPharmacies(slug, data) {
        return authAxios.post(`/recommended-pharmacies/${slug}/add`, data, { withCredentials: true });
    }

    clientAddActionToPharmacies(slug, data) {
        return authAxios.post(`/pharmacies/${slug}/actions/add`, data, { withCredentials: true });
    }

    getConsultedPharmacies(page, limit) {
        let params = {
            page: page,
            limit: limit
        }
        return authAxios.get('/consulted-pharmacies', { params, withCredentials: true });
    }

    getCurrentPatient() {
        return authAxios.get(`/get-current-patient`, { withCredentials: true });
    }

    resendResetPasswordEmail(data) {
        return authAxios.post(`/reset-password`, data)
    }

    resendResetPassword(token, data) {
        return authAxios.post(`/reset-password/reset/${token}`, data)
    }

    updatePassWord(data) {
        return authAxios.patch(`/change-current-patient-password`, data, { withCredentials: true })
    }

    logout() {
        localStorage.clear();
        this.authenticated = false;
        window.location.href = api_url.substr(0, api_url.length - 4) + "auth/patient/revoke";
    }

    getAllPrescriptions(page, limit) {
        let params = {
            page: page,
            limit: limit
        }
        return authAxios.post('/prescriptions/get', '', { params, withCredentials: true });
    }

    caancelPrescrirption(cancellationReasonId, prescriptionId) {
        const data = {
            cancellationReasonId: cancellationReasonId
        }
        return authAxios.patch(`/prescriptions/${prescriptionId}/cancel`, data, { withCredentials: true })
    }

    getCancellationReasons() {
        return authAxios.get(`/cancellation-reasons`, { withCredentials: true });
    }

    getPatientLocalisation() {
        return authAxios.get(`/location`, { withCredentials: true });
    }

}

export default new patientsService()


