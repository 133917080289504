import React, { Component } from 'react';
import SearchErrourComponent from '../../helpers/SearchErrourComponent/SearchErrourComponent';
import { Helmet } from 'react-helmet';
import Footer from '../../helpers/Footer/Footer';
import Header from '../../helpers/Header/Header';
import PharmacienCard from '../../helpers/PharmacienCard/PharmacienCard';
import pharmaciesServices from '../../services/pharmacies.services';
import patientsService from '../../services/patients.service';
import SearchSelect from '../../helpers/AsyncSelect';
// import Pub1 from '../../Images/Pub1.gif';
import Pub2 from '../../Images/bannière_Web.gif';
import Pub1 from '../../Images/SkinAnalysiaDisplay.gif';
import { api_url } from '../../config';
import axios from 'axios';
import './SearchResultPage.css';
import Loader from '../../helpers/Loader';

const authAxios = axios.create({
    baseURL: api_url
});

class SearchResultPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nomDeParmacie: '',
            lieuxDeParmacie: '',
            Pharmacies: [],
            currentPage: 1,
            name: "",
            searched: null,
            cityId: 0,
            sectorId: null,
            limit: 9,
            selectedCity: null,
            searchedPharmacie: false,
            totalItems: null,
            authonticatedUser: null,
            allData: null,
            noResult: false,
            country: null,
            loader: true
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.title = " MonPharmacien - 1er annuaire interactif de pharmacies au Maroc";
        const name = this.props.match && this.props.match.params.name;
        const localisation = this.props.match && this.props.match.params.localisation;
        const searched = {
            city: {
                id: this.props.location.state?.cityId
            }
        }
        if (name || localisation) {
            this.getCityAndSectors(localisation);
        }
        const selectedCity = this.props && this.props.location.state ? this.props.location.state.selectedCity : ""
        this.setState({ nomDeParmacie: this.props && this.props.location.state && this.props.location.state?.pharmacieName, selectedCity: selectedCity, cityId: this.props.location.state?.cityId, sectorId: this.props.location.state?.sectorId, searched })
        this.getAllPharmacies(this.props.location.state?.sectorId, this.props.location.state?.cityId, this.props.location.state?.pharmacieName);
        this.getCurrentPatient()
    }

    handleChange = (input) => (event) => {
        this.setState({ [input]: event.target.value });
    }

    getCityAndSectors = async (localisation) => {
        const response = await authAxios.get(`/cities/deep-search?name=${localisation}`);
        this.setState({ allData: response.data }, () => {
            // convertedArray = convertAllDataForSelectBox(allData, optionValueProperty, optionLabelProperty);
        });
    }

    getAllPharmacies = async (sectorId, cityId, nomDeParmacie) => {
        const { currentPage, limit } = this.state
        try {
            let data = {
                name: nomDeParmacie,
                cityId: cityId,
                sectorId: sectorId
            }
            let response = await pharmaciesServices.getAllPharmacies(currentPage, limit, data);
            this.setState({ Pharmacies: response.data.pharmacies, searchedPharmacie: true, totalItems: response.data.total, loader: false }, () => {
                if (this.state.Pharmacies.length === 0) {
                    this.setState({ noResult: true })
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    getAllPharmaciesHere = async () => {
        const { currentPage, limit, searched, nomDeParmacie } = this.state
        this.setState({ loader: true, noResult: false });
        try {
            let data = {
                name: nomDeParmacie,
                cityId: searched?.city && searched.city?.id,
                sectorId: searched?.sector && searched.sector?.id
            }
            let response = await pharmaciesServices.getAllPharmacies(currentPage, limit, data);
            this.props.history.push(`/pharmacies`)
            this.setState({ Pharmacies: response.data.pharmacies, searchedPharmacie: true, totalItems: response.data.total, loader: false }, () => {
                if (this.state.Pharmacies.length === 0) {
                    this.setState({ noResult: true })
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    getCurrentPatient = async () => {
        try {
            let response = await patientsService.getCurrentPatient();
            this.setState({ country: response.data?.patientData?.country })
        } catch (error) {
            console.log(error);
            this.getPatientLocalisation()
        }
    }


    getPatientLocalisation = async () => {
        try {
            let response = await patientsService.getPatientLocalisation()
            this.setState({ country: response.data?.countryCode })
        } catch (error) {
            console.log(error);
        }
    }

    handleKeyDown = (e) => {
        if (e.key === "Enter") {
            const searched = this.state.searched
            const nomDeParmacie = this.state.nomDeParmacie
            this.setState({ loader: true });
            this.getAllPharmaciesHere(searched, nomDeParmacie)
        }
    }

    render() {
        const { searched, nomDeParmacie, currentPage, totalItems, limit, noResult, loader, country } = this.state
        return (
            <div className="SearchResultPage">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>MonPharmacien - 1er annuaire interactif de pharmacies au Maroc</title>
                    <meta name="description" content="1er annuaire interactif de pharmacies au Maroc. Il permet aux pharmaciens de mettre en ligne leurs tours de garde et aux patients de trouver la pharmacie la plus proche et de communiquer avec leurs pharmaciens." />
                    <meta name="theme-color" content="#00c389" />
                    <meta name="keywords" content=" Pharmacie de garde,Pharmacie,Officine,Pharmacie à “Localisation”, Pharmacie NOM, Pharmacie NOM à Localisation" />
                </Helmet>
                {loader && <Loader />}
                <Header />
                <div className="SearchComponent " style={{ padding: "2%" }}>
                    <div className="container">
                        <div className="col-10 offset-1">
                            <div className="row">
                                <div className="col-12">
                                    <div className="bloc_search container">
                                        <form method="get">
                                            <div className="form-row align-items-center">
                                                <div className="col-3">
                                                    <input type="text" className="form-control SearchInputs" id="name" name="name" placeholder="Nom de la pharmacie" value={this.state.nomDeParmacie} onChange={this.handleChange("nomDeParmacie")} onKeyPress={this.handleKeyDown} />
                                                </div>
                                                <div className="col-6" onKeyPress={this.handleKeyDown}>
                                                    <SearchSelect
                                                        className="form-control SearchInputs"
                                                        fetchedUrl='/cities/deep-search'
                                                        HTTPMethod="getall"
                                                        receivedObject="cities"
                                                        optionLabelProperty="name"
                                                        noOptoins="Saisissez au moins un caractère"
                                                        optionValueProperty="id"
                                                        value={this.state.selectedCity}
                                                        country={country}
                                                        placeholder="Où ? (Ville, Secteur, Code postal...)"
                                                        onChange={(e) => {
                                                            if (e) {
                                                                this.setState({ selectedCity: [], cities: [] }, () => {
                                                                    let city = {
                                                                        id: e.value
                                                                    }
                                                                    this.state.searched = city;
                                                                    this.setState({
                                                                        searched: e.value,
                                                                        selectedCity: [...this.state.selectedCity, e]
                                                                    })
                                                                })
                                                            }
                                                        }}
                                                        styles={{
                                                            loadingIndicator: (provided, state) => ({
                                                                ...provided,
                                                                display: "none",
                                                            }),
                                                            indicatorSeparator: (provided, state) => ({
                                                                ...provided,
                                                                display: "none",
                                                            }),
                                                            container: (provided, state) => ({
                                                                ...provided,
                                                            }),
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                borderWidth: "2px",
                                                                borderRadius: "none",
                                                                boxShadow: "none",
                                                                borderRadius: "3px",
                                                                borderColor: "#e5eef7",
                                                                height: '42px',
                                                                ":hover": {
                                                                    borderColor: "none",
                                                                    borderWidth: "2px"
                                                                },
                                                            }),
                                                            input: (provided, state) => ({
                                                                ...provided,
                                                                fontFamily: "OpenSans",
                                                                fontWeight: "600",
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                backgroundColor: state.isSelected && "#ffff",
                                                                fontFamily: "OpenSans",
                                                                borderBottom: " 1px solid #e5eef7",
                                                                padding: "5px",
                                                                color: "#52747d",
                                                                fontSize: "13px",
                                                                textAlign: 'start',
                                                                ":hover": {
                                                                    color: "#fff",
                                                                    backgroundColor: "#1bc48a",
                                                                },
                                                            }),
                                                            clearIndicator: (provided, state) => ({
                                                                ...provided,
                                                                paddingRight: 0,
                                                            }),
                                                            dropdownIndicator: (provided, state) => ({
                                                                ...provided,
                                                                paddingLeft: 0,
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <button type="button" className="btn btn-success submitSearch " id="getPharmacies" onClick={() => this.getAllPharmaciesHere(searched, nomDeParmacie)} onKeyPress={this.handleKeyDown}>Chercher</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="SearchResultComponent container">
                    <div className="col-10 offset-1 d-flex py-4">
                        <div className={this.state.Pharmacies.length !== 0 ? "row col-10" : "row col-12"}>
                            {this.state.Pharmacies.length !== 0 &&
                                this.state.Pharmacies.map((item, i) => {
                                    return <div className="LastPharmaciesCards col-lg-4 col-md-6 py-2">
                                        <PharmacienCard item={item} key={i} />
                                    </div>
                                })}
                            {noResult && <SearchErrourComponent />}
                            {/* {this.state.Pharmacies.length !== 0 ?
                                currentPage > totalItems / limit && (
                                    <div className="LastPharmaciesCards col-lg-4 col-md-6 py-2">
                                        <div className="PharmacienCard" onClick={() => {
                                            this.props.history.push(`/`)
                                            window.scrollTo(0, 0)
                                        }}>
                                            <div className="card">
                                                <div className="PhotoAndtitleOfCard p-2">
                                                    <img className="card-img-top" src={emptyCard} alt="Card image cap" style={{ width: "95%", margin: 'auto' }} />
                                                </div>
                                                <div className="emptyPhotoAndtitleOfCard">
                                                    <a className="empty_bloc_pharmacie_title">Vous ne trouvez pas votre pharmacie ?</a>
                                                </div>
                                                <div className="card-body ">
                                                    <a className="btn CardButtonEmpty w-100" onClick={() => {
                                                        this.props.history.push('/')
                                                        window.scrollTo(0, 0)
                                                    }}>Suggérer une pharmacie</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>) : ""} */}

                            {this.state.Pharmacies.length !== this.state.totalItems ?
                                <div className="py-3" style={{ width: "100%" }}>
                                    <div className="Paginationbutton d-flex justify-content-end" >
                                        <ul className="pagination">
                                            <li className="page-item">
                                                {currentPage > 1 && (
                                                    <a className="page-link" onClick={() => this.setState({
                                                        currentPage: currentPage - 1
                                                    }, () => {
                                                        this.getAllPharmaciesHere();
                                                        window.scrollTo(0, 0);
                                                    })} aria-label="Previous">
                                                        <span aria-hidden="true"><i className="fas fa-chevron-left pagination_icon"></i></span>
                                                    </a>
                                                )}
                                            </li>
                                            <li className="page-item"><a className="page-link">{currentPage}</a></li>
                                            <li className="page-item">
                                                {
                                                    currentPage < totalItems / limit && (
                                                        <a className="page-link" onClick={() => this.setState({
                                                            currentPage: currentPage + 1
                                                        }, () => {
                                                            this.getAllPharmaciesHere();
                                                            window.scrollTo(0, 0);
                                                        })
                                                        } aria-label="Previous">
                                                            <span aria-hidden="true"><i className="fas fa-chevron-right pagination_icon"></i></span>
                                                        </a>)
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                : ""}

                        </div>
                        {this.state.Pharmacies.length !== 0 ?
                            <div className="row col-2">
                                <div className="py-2">
                                    <div className="results_content_right">
                                        <div className="bloc_pub pb-3">
                                            <a href='https://docs.google.com/forms/d/e/1FAIpQLSekRQi0RHQ_HM5UmEAyFgxfFwTyS2bSVVE1_ENxCfWEGugVJw/viewform?pli=1&pli=1' target="_blank" style={{ cursor: "pointer" }}>

                                                <img src={Pub1} alt="Test Covid-19 en ligne." className="blocPubImage" title="Test Covid-19 en ligne." />
                                            </a>
                                        </div>
                                        <div className="bloc_pub pb-3">
                                            <a onClick={() => window.open('https://www.facebook.com/groups/386598379672008')} style={{ cursor: "pointer" }}>
                                                <img src={Pub2} alt="Inscrivez-vous dès maintenant !" className="blocPubImage" title="Inscrivez-vous dès maintenant !" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div> : ""}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default SearchResultPage;
