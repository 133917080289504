import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const Loader = () => {
    return (
        <div className="LoadingContainer">
            <ScaleLoader height={50} width={10} radius={4} margin={4} color={"#00c389"} />
        </div>
    );
};

export default Loader;