import React, { Component } from 'react';
import Facebook from '../../Images/fb.png'
import WhatsApp from '../../Images/whts.png'
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import './Share.css';

class Share extends Component {
    render() {
        return (
            <div className="Share">
                <div className="card ShareCard">
                    <div className="card-header ShareCardHeader">
                        <i className="fas fa-project-diagram mr-2"></i>
                Partager sur
</div>
                    <div className="card-body ShareCardBody d-flex justify-content-center ">
                        <FacebookShareButton url={window.location.href} >
                            <img className="Facebook mr-3" src={Facebook} alt="Facebook" />
                        </FacebookShareButton>
                        <WhatsappShareButton url={window.location.href}>
                            <img className="Facebook" src={WhatsApp} alt="Facebook" />
                        </WhatsappShareButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default Share;