import React, { Component } from 'react';
import ModalLayout from '../../ModalLayout/ModalLayout';
import './PhoneNumberModa.css';


class PhoneNumberModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            img: '',
            phoneNumber: null
        };
    }

    componentDidMount() {
        const PhoneNumber = this.props && this.props.PhoneNumber;
        this.setState({ PhoneNumber }, () => {
            this.creatImage(this.state.PhoneNumber);
        })
    }

    componentDidUpdate(prevState, nextState) {
        if (prevState.PhoneNumber !== nextState.PhoneNumber) {
            this.setState({ PhoneNumber: this.props.PhoneNumber }, () => {
                if (this.props.PhoneNumber) {
                    this.creatImage(this.state.PhoneNumber);
                }else{
                    this.creatImage("");
                    
                }
            })
        }
    }

    creatImage = (PhoneNumber) => {
        let canvasTxt = document.getElementById("canvasComponent").getContext("2d");
        canvasTxt.canvas.width = canvasTxt.measureText(PhoneNumber).width + 10;
        canvasTxt.canvas.height = 20;
        canvasTxt.canvas.fillStyle = "#00c389";
        canvasTxt.fillStyle = "#00c389";
        canvasTxt.canvas.font = "16px openSans";
        canvasTxt.font = "16px openSans";
        canvasTxt.fillText(PhoneNumber, 0, 15);
        this.setState({ img: canvasTxt.canvas.toDataURL() });
    }

    render() {
        return (
            <ModalLayout title="NUMERO DE TÉLÉPHONE" lg={false} bodyParagraphe="Le numéro téléphone de la pharmacie" id="PhoneNumberModal">
                <div className="NumberModal d-flex align-items-center">
                    <i className="fas fa-phone-alt mr-3"></i>
                    {/* <p className="NumberModal_paragraphe" id='my-node' style={{}}>{this.props && this.props.PhoneNumber?.split(/(?=(?:..)*$)/).join('  ')}</p> */}
                    <canvas id="canvasComponent" style={{ display: "none" }} />
                    {this.state.img.length > 0 ?
                        <img id="imageComponent" src={this.state.img} />
                        : null}
                </div>
            </ModalLayout>
        );
    }
}

export default PhoneNumberModal;